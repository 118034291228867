import React from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import ListHeader from "./ListHeader";
import ListFooter from "./ListFooter";
import ABKListViewItem from "./ABKListViewItem";

interface ABKListViewProps {
  data: any[];
  onDataChange: (newData: any[]) => void;
  itemFields: string[];
  headerText?: string;
  footerText?: string;
  onAddItem: () => void;
  addButtonText: string;
}

const ABKListView = (props: ABKListViewProps) => {
  const { data, onDataChange, onAddItem } = props;

  const [activeId, setActiveId] = React.useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over.id);
      const newData = arrayMove(data, oldIndex, newIndex);
      if (onDataChange) {
        onDataChange(newData);
      }
    }

    setActiveId(null);
  };

  const handleDeleteItem = (id: string) => {
    const newData = data.filter((item) => item.id !== id);
    if (onDataChange) {
      onDataChange(newData);
    }
  };

  const handleEditItem = (id: string, newName: string) => {
    const newData = data.map((item) =>
      item.id === id ? { ...item, name: newName } : item
    );
    if (onDataChange) {
      onDataChange(newData);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {props.headerText && <ListHeader headerText={props.headerText} />}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={data.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          {data.map((item) => (
            <ABKListViewItem
              key={item.id}
              id={item.id}
              name={item.name}
              onDelete={handleDeleteItem}
              onEdit={handleEditItem}
            />
          ))}
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <div
              style={{
                padding: 10,
                borderBottom: "1px solid lightgrey",
                backgroundColor: "lightgrey",
              }}
            >
              {data.find((item) => item.id === activeId)?.name}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
      <ListFooter
        footerText={`${data.length} ${props.footerText}`}
        onAddItem={onAddItem}
        addButtonText={props.addButtonText}
      />
    </div>
  );
};

export default ABKListView;

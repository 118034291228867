import { useNavigate } from "react-router-dom";
import ABKBasisGrid from "../../../../../abk-shared/components/organisms/ABKBasisGrid";
import { ParameterListe } from "../../../interfaces/parameterlisten";
import useParameterlisten from "./useParameterlisten";
import ABKCompactMobileCard from "../../../../../abk-shared/components/molecules/ABKCompactMobileCard";
import { styleVersionNumber } from "../../../../../abk-shared/utility/styleVersionNumber";

const PLGrid = ({ parameterlisten }: { parameterlisten: ParameterListe[] }) => {
  const { columnsDefinition, customCells, gridActions } = useParameterlisten();
  const navigate = useNavigate();

  return (
    <ABKBasisGrid
      data={parameterlisten}
      columnsDefinition={columnsDefinition}
      gridId="plGrid"
      dataItemKey="DATAID"
      gridActions={gridActions}
      customCells={customCells}
      mobileGridRow={(
        rowRef,
        item,
        filterValue,
        isSelected,
        setDataItemSelected
      ) => (
        <ABKCompactMobileCard
          rowRef={rowRef}
          item={item}
          filterValue={filterValue}
          isSelected={isSelected}
          setDataItemSelected={setDataItemSelected}
          onRowClick={(item: ParameterListe) => {
            navigate(`${item.DATAID}`);
          }}
          cardTitle={item.Kenndaten.BEZ}
          cardExtraTitle={item.Kenndaten.KENNUNG}
          cardSubtitle={`Version ${styleVersionNumber(item.Kenndaten.VERSIONSNR)}`}
        />
      )}
    />
  );
};

export default PLGrid;

import { VariableSizeList } from "react-window";
import { FunctionSetDataItemSelected } from "../hooks/useGridRowSelection/useGridRowSelection";
import { SELECTED_FIELD } from "../hooks/useGridColumns/COLUMN_SELECTED_FIELD";
import CardContainer from "../../../molecules/ABKCardContainer";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { GenericObject } from "../../../../interfaces/GenericObject";
import { ungroupDataItems } from "../utils/groupingFunctions";

export type FunctionMobileGridRow = (
  rowRef: React.RefObject<HTMLDivElement>,
  item: GenericObject,
  filterValue: string,
  isSelected: boolean,
  setDataItemSelected: FunctionSetDataItemSelected
) => React.ReactElement;

// Für das erste Render benutzt, wenn wir die Größe der Rows messen
const ROW_DEFAULT_HEIGHT = 82;

// Code inspiriert von: https://codesandbox.io/s/react-chat-simulator-yg114?file=/src/ChatRoom/Room/Messages/Messages.js:295-302
type Props = {
  dataItems: GenericObject[];
  mobileGridRow: FunctionMobileGridRow;
  filterValue: string;
  setDataItemSelected: FunctionSetDataItemSelected;
};

export default function VirtualizedMobileGridRows({
  dataItems,
  mobileGridRow,
  filterValue,
  setDataItemSelected,
}: Props) {
  const flattenedDataItems = React.useMemo(
    () => ungroupDataItems(dataItems),
    [dataItems]
  );

  const listRef = React.useRef<VariableSizeList>(null);
  const rowHeights = React.useRef<Record<string, number>>({});

  const getItemSize = React.useCallback(
    (index: number) => {
      return rowHeights.current[index] || ROW_DEFAULT_HEIGHT;
    },
    [rowHeights]
  );

  const setRowHeight = React.useCallback(
    (index: number, size: number) => {
      if (listRef.current) listRef.current.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    },
    [listRef, rowHeights]
  );

  const Row = ({ index, style }: { index: number; style: any }) => {
    const rowRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef, index]);

    return (
      <div style={style}>
        {mobileGridRow(
          rowRef,
          flattenedDataItems[index],
          filterValue,
          flattenedDataItems[index][SELECTED_FIELD],
          setDataItemSelected
        )}
      </div>
    );
  };

  return (
    <CardContainer hasGridLayout={false}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <VariableSizeList
            ref={listRef}
            height={height}
            width={width}
            itemCount={flattenedDataItems.length}
            itemSize={getItemSize}
          >
            {Row}
          </VariableSizeList>
        )}
      </AutoSizer>
    </CardContainer>
  );
}

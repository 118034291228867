import { GridCustomHeaderCellProps } from "@progress/kendo-react-grid";
import { CSSProperties } from "react";
import { SELECTED_FIELD } from "../hooks/useGridColumns/COLUMN_SELECTED_FIELD";
import { GridColumnPropsCustom } from "../interfaces/GridColumns";
import React from "react";
import { forEachColumns } from "../hooks/useGridColumns/columnUtils";

type Props = GridCustomHeaderCellProps & {
  columns: GridColumnPropsCustom[];
  isColumnMenuActive: (columnField: string | undefined) => boolean;
};
export default function HeaderCustomCell(props: Props) {
  let className = props.thProps?.className ?? "";
  let style: CSSProperties = {
    ...props.thProps?.style,
    lineHeight: "normal", // Um den Text im Header nicht zu schneiden
  };

  const headerIsColumnGroup = React.useMemo(() => {
    let headerIsColumnGroup = false;
    forEachColumns(props.columns, (column) => {
      if (column.field !== props.field) return;
      const hasChildren =
        Array.isArray(column.children) && column.children.length > 0;
      if (hasChildren) headerIsColumnGroup = true;
    });
    return headerIsColumnGroup;
  }, [props.field, props.columns]);

  if (headerIsColumnGroup) {
    className = `${className} k-justify-content-center`;
    style.fontSize = "smaller";
  }

  const isActive = props.isColumnMenuActive(props.field);
  if (isActive) className = `${className} active-column-menu`;

  /*
    Verhindere, dass der Anweder mit den Gruppenspalten ein Risizing macht.
    Der Grund dafür ist, die Breite dieser Spalten sind nicht im localStorage gespeichert.
  */
  const columnCanBeResized =
    props.field !== SELECTED_FIELD && !headerIsColumnGroup;

  const thProps = Object.assign({}, props.thProps);
  // Remove React warnings
  if (thProps) {
    const ariaSortValue = thProps.ariaSort;
    delete thProps.ariaSort;
    thProps["aria-sort"] = ariaSortValue;

    const ariaColumnIndexValue = thProps.ariaColumnIndex;
    delete thProps.ariaColumnIndex;
    thProps["aria-colindex"] = ariaColumnIndexValue;

    const ariaSelectedValue = thProps.ariaSelected;
    delete thProps.ariaSelected;
    thProps["aria-selected"] = ariaSelectedValue;

    const ariaDescriptionValue = thProps.ariaDescription;
    delete thProps.ariaDescription;
    thProps["aria-description"] = ariaDescriptionValue;

    const columnIdValue = thProps.columnId;
    delete (thProps as any).columnId;
    (thProps as any)["columnid"] = columnIdValue;
  }

  thProps.title = props.title;

  if (columnCanBeResized)
    return (
      <th {...thProps} key={thProps?.key} className={className} style={style}>
        {props.children}
      </th>
    );

  let childrenWithoutResizer = props.children;
  if (props.children && Array.isArray(props.children))
    childrenWithoutResizer = props.children.slice(0, -1);

  return (
    <th {...thProps} key={thProps?.key} className={className} style={style}>
      {childrenWithoutResizer}
    </th>
  );
}

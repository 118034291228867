import { SelectedState } from "../hooks/useGridRowSelection/useGridRowSelection";
import { GenericObject } from "../../../../interfaces/GenericObject";

export const getSelectedItems = (
  items: GenericObject[],
  selectedState: SelectedState,
  idGetter: Function
) =>
  items.filter((container) =>
    isItemSelected(container, selectedState, idGetter)
  );

const isItemSelected = (
  item: GenericObject,
  selectedState: SelectedState,
  idGetter: Function
) => selectedState[idGetter(item)];

/*
  The code in the following function is inspired by the following example:
  https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-search/
*/
export function getNestedValue(
  fieldName: string | undefined,
  dataItem: GenericObject
) {
  const path = (fieldName || "").split(".");
  path.forEach((p) => {
    dataItem = dataItem ? dataItem[p] : undefined;
  });
  return dataItem;
}

export const isFilterPatternMatchingValue = (
  filterPattern: string,
  value: string
) => value.toLocaleLowerCase().indexOf(filterPattern.toLocaleLowerCase()) >= 0;

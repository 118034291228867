export function styleVersionNumber(versionNumber: string) {
  const numberOfDigits = versionNumber.toString().length;

  if (numberOfDigits === 1) {
    return `00${versionNumber}`;
  } else if (numberOfDigits === 2) {
    return `0${versionNumber}`;
  } else {
    return versionNumber;
  }
}

import HerausgeberForm from "./panels/Herausgeber/HerausgeberForm";
import "./styles.scss";
import PageTemplate from "../../PageTemplate";
import AllgemeinForm from "./panels/Allgemein/AllgemeinForm";
import {
  ParameterListe,
  ParameterListeStatus,
} from "../../../interfaces/parameterlisten";
import useGetSingleParameterliste from "../../../queries/useGetSingleParameterliste";
/*
  Dieser Import ist für die Vorversion, auskommentiert, um einen Spur für die Vorversion
  der Gliederungssysteme zu behalten.
  Wenn die Vorversion für die Gliederungssystem implementiert ist,
  dann kann der folgende Code gelöscht werden, mit diesem Kommentar.
*/
// import useChangePageTitleKenndaten from "./useChangePageTitleKenndaten";
import useSaveParameterListe from "../../../queries/useSaveParameterListe";
import { ParameterListeDraft, db } from "../../../../../core/db/db";
import { AxiosError } from "axios";
import _ from "lodash";
import useDrafts from "../../../hooks/useDrafts";
import { ABKPageLoader } from "../../../../../abk-shared/components/atoms/ABKPageLoader";
import { Versioned } from "../../../../../core/interfaces/Versioned";
import BeschreibungForm from "./panels/Beschreibung/BeschreibungForm";
import { useParams } from "react-router-dom";

type Props = { dbName: string; contId: string };
export const Kenndaten = ({ dbName, contId }: Props) => {
  /*
    Hier wurde die Vorversion auskommentiert. Die sollte nicht für die
    Parameterlisten verfügbar sein. Aber wurde trotzdem implementiert.
    Der folgende Code bleibt da, weil die Vorversion für die Gliederungssysteme
    implementiert sein wird. Und dieser Code wird uns dabei helfen.
  */
  // useChangePageTitleKenndaten(isPreviousVersion, dbName, contId);

  const { plID } = useParams();

  const {
    data: parameterliste,
    isLoading,
    isFetching,
    error: parameterlisteLoadingError,
    refetch,
  } = useGetSingleParameterliste(dbName, contId, plID!);

  const { mutateAsync } = useSaveParameterListe();

  const is404 =
    parameterlisteLoadingError instanceof AxiosError &&
    parameterlisteLoadingError.status === 404;

  // -----------------------------------------------------------------------------------
  // @Michael, wenn du das siehst, bitte folgendes bild im browser öffnen:
  // https://i.pinimg.com/originals/52/ee/77/52ee7751e0fe6df4fdb7513a1b16102f.jpg
  // -----------------------------------------------------------------------------------

  const {
    isEdited,
    handleReset,
    handleSave,
    pageState,
    updatePageState,
    sections,
    updateSections,
    conflictMessage,
  } = useDrafts<ParameterListe, ParameterListeDraft, [string, string]>({
    is404,
    isFetching,
    initialPageState: parameterliste,
    initialSections: new Map([
      ["allgemein", true],
      ["herausgeber", false],
      ["beschreibung", false],
    ]),
    backend: {
      doSave: async (parameterliste: Versioned<ParameterListe>) =>
        mutateAsync({
          db: dbName,
          contId,
          parameterliste,
        }),
      doRefetch: refetch,
    },
    drafts: {
      key: (pl: ParameterListe) => [pl.CONTID, pl.DATAID],
      new: (
        data: Versioned<ParameterListe>,
        sections: Map<string, boolean>
      ): ParameterListeDraft => ({
        database: dbName,
        contId: data.value.CONTID,
        dataId: data.value.DATAID,
        etag: data.etag,
        edited: _.cloneDeep(data.value),
        sections: sections,
      }),
      table: db.parameterListeDrafts,
    },
  });

  if (isLoading) {
    return <ABKPageLoader />;
  }

  if (!pageState) {
    return <div>Es stehen noch keine Parameter zur Verfügung</div>;
  }

  const readOnly =
    pageState.value.Kenndaten.STATUS === ParameterListeStatus.Freigegeben;

  return (
    <PageTemplate
      db={dbName}
      contId={contId}
      balkenProps={{
        edited: is404 || isEdited,
        onSave: handleSave,
        onReset: handleReset,
        conflictMessage,
      }}
    >
      <AllgemeinForm
        pageState={pageState.value}
        setPageState={updatePageState}
        isOpen={sections.get("allgemein") ?? false}
        updateSections={() => {
          const newSections = new Map(sections);
          newSections.set("allgemein", !sections.get("allgemein"));
          updateSections(newSections);
        }}
        readOnly={readOnly}
      />
      <HerausgeberForm
        pageState={pageState.value}
        setPageState={updatePageState}
        isOpen={sections.get("herausgeber") ?? false}
        updateSections={() => {
          const newSections = new Map(sections);
          newSections.set("herausgeber", !sections.get("herausgeber"));
          updateSections(newSections);
        }}
        readOnly={readOnly}
      />
      <BeschreibungForm
        pageState={pageState.value}
        setPageState={updatePageState}
        isOpen={sections.get("beschreibung") ?? false}
        updateSections={() => {
          const newSections = new Map(sections);
          newSections.set("beschreibung", !sections.get("beschreibung"));
          updateSections(newSections);
        }}
        readOnly={readOnly}
      />
    </PageTemplate>
  );
};

import React from "react";
import "./style.scss";
import { FunctionSelectionChange as FunctionOnSelectionChange } from "./hooks/useGridRowSelection/useGridRowSelection";
import { CustomCellObject } from "./components/CustomCell";
import { CreateGridActionsFunction } from "./interfaces/GridAction";
import { GridColumnsDefinition } from "./interfaces/GridColumns";
import { isMobile } from "../../../utility/detectDevice";
import { useResizeDetector } from "react-resize-detector";
import { Loader } from "@progress/kendo-react-indicators";
import BasisGrid from "./BasisGrid";
import { FunctionMobileGridRow } from "./components/VirtualizedMobileGridRows";
import { GenericObject } from "../../../interfaces/GenericObject";

export type ABKBasisGridProps = {
  data: GenericObject[];
  columnsDefinition: GridColumnsDefinition;
  gridId: string;
  dataItemKey: string;
  mobileGridRow?: FunctionMobileGridRow;
  gridActions?: CreateGridActionsFunction;
  onSelectionChange?: FunctionOnSelectionChange;
  isLoading?: boolean;
  customCells?: CustomCellObject[];
  groupable?: boolean;
  sortable?: boolean;
  initialSelectedItems?: GenericObject[];
};

const ABKBasisGrid: React.FC<ABKBasisGridProps> = (
  props: ABKBasisGridProps
) => {
  const { width, ref: wrapperGridRef } = useResizeDetector();
  const isCalculatingWidth = width == null;
  const isSmallGrid = !isCalculatingWidth && width < 600;
  const isMobileGrid = isMobile() || isSmallGrid;

  let classNameWrapperGrid = "wrapper-grid";
  if (isCalculatingWidth) classNameWrapperGrid += " center-loader";

  return (
    <div ref={wrapperGridRef} className={classNameWrapperGrid}>
      {isCalculatingWidth ? (
        <Loader size="large" type="infinite-spinner" />
      ) : (
        <BasisGrid
          {...props}
          isMobileGrid={isMobileGrid}
          wrapperGridRef={wrapperGridRef}
          initialSelectedItems={props.initialSelectedItems}
        />
      )}
    </div>
  );
};

export default ABKBasisGrid;

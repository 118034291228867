import React from "react";
import { FunctionSetDataItemSelected } from "./useGridRowSelection/useGridRowSelection";
import { GenericObject } from "../../../../interfaces/GenericObject";

export type GridContextMenuHandler = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  offset: React.MutableRefObject<{ left: number; top: number }>;
  handleContextMenuOpen: (e: any, dataItem: GenericObject) => void;
};

function useGridContextMenu(setDataItemSelected: FunctionSetDataItemSelected) {
  const [show, setShow] = React.useState(false);

  const offset = React.useRef({
    left: 0,
    top: 0,
  });

  const handleContextMenuOpen = (
    event: React.MouseEvent<any, MouseEvent>,
    dataItem: GenericObject
  ) => {
    event.preventDefault();
    offset.current = {
      left: event.pageX,
      top: event.pageY,
    };
    setShow(true);
    // When right-clicking, adding this "dataItem" to the currently selected dataItems
    setDataItemSelected(dataItem);
  };

  return {
    show,
    setShow,
    handleContextMenuOpen,
    offset,
  };
}

export default useGridContextMenu;

import { Input, InputHandle } from "@progress/kendo-react-inputs";
import { AbkIconsMap } from "../../../../../interfaces/abk9SvgIcons";
import { isMobile } from "../../../../../utility/detectDevice";
import { SvgIcon } from "@progress/kendo-react-common";

type Props = {
  filterValue: string;
  onFilterChange: (newValue: string) => void;
  inputRef: React.RefObject<InputHandle>;
  shouldDisplayLabel?: boolean;
  children?: React.ReactElement;
};
const ABKSearchBar = ({
  filterValue,
  onFilterChange,
  inputRef,
  shouldDisplayLabel = true,
  children,
}: Props) => (
  <span
    className="grid-toolbar-search"
    data-testid="wrapper-input-mobile-grid-search"
  >
    <span title="Suche">
      <SvgIcon width={18} icon={AbkIconsMap.get("General.Search")} />
    </span>
    {shouldDisplayLabel && <>Suche:</>}
    <Input
      ref={inputRef}
      placeholder={isMobile() ? "" : "Strg+E"}
      value={filterValue}
      onChange={(event) => onFilterChange(event.value)}
      onKeyDown={(event) => {
        if (event.key !== "Escape") return;

        if (filterValue !== "") {
          const newValue = "";
          onFilterChange(newValue);
          return;
        }

        if (inputRef.current) (inputRef.current as any)._input.blur();
      }}
    />
    {children}
  </span>
);

export default ABKSearchBar;

import {
  DropDownListChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import React from "react";
import ABKExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormDropdown from "../../../../../../../abk-shared/components/atoms/ABKFormDropdown";
import ABKFormFieldInput from "../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import { hideParList, hideParMapper, parTypeDisplayedList } from "../../maps";
import { useEintragBearbeitenState } from "../useEintragBearbeitenState";

const AllgemeinPanel = () => {
  const { pageState, setPageState } = useEintragBearbeitenState();

  return (
    <ABKExpansionPanel defaultExpanded={true} title="Allgemein">
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormDropdown
                  label="Kategorie"
                  value={pageState?.ITEMTYPE}
                  data={parTypeDisplayedList}
                  editorId="PARTYP"
                  onChange={(event: DropDownListChangeEvent) =>
                    setPageState({
                      ...pageState,
                      ITEMTYPE: event.value,
                    } as EintragFrontend)
                  }
                />
                <ABKFormFieldInput
                  label="Bezeichnung"
                  value={pageState?.BEZ}
                  editorId="BEZ"
                  setValue={(value: string) =>
                    setPageState({
                      ...pageState,
                      BEZ: value,
                    } as EintragFrontend)
                  }
                />
              </ABKFormRow>,

              <ABKFormRow isHalfRow={true}>
                <ABKFormDropdown
                  label="Ausblenden"
                  value={pageState?.SHOWIF}
                  data={hideParList}
                  valueRender={hideParValueRender}
                  itemRender={hideParItemRender}
                  editorId="HIDEPAR"
                  onChange={(event: DropDownListChangeEvent) =>
                    setPageState({
                      ...pageState,
                      SHOWIF: event.value,
                    } as EintragFrontend)
                  }
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKExpansionPanel>
  );
};

const hideParValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any
) => {
  if (value === "") {
    const children = [<span key={value}>{hideParMapper.get("")}</span>];
    return React.cloneElement(element, element.props, children);
  }

  const children = [<span key={value}>{hideParMapper.get(value)}</span>];
  return React.cloneElement(element, element.props, children);
};

const hideParItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const mappedName = hideParMapper.get(itemProps.dataItem);
  const itemChildren = <span key={mappedName}>{mappedName}</span>;
  return React.cloneElement(li, li.props, itemChildren);
};

export default AllgemeinPanel;

import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";
import EintraegePage from "./EintraegePage";

const Eintraege = () => {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  if (!isValid) return <></>;

  return (
    <EintraegePage
      key={`eintraege-${db}-${contId}`}
      db={db as string}
      contId={contId as string}
    />
  );
};

export default Eintraege;

import { IField } from "./IField";

interface EintragBearbeitenExpansionPanelVorschauProps {
  title: string;
  fields: IField[];
  shouldShowPreview: boolean;
}
const EintragBearbeitenExpansionPanelVorschau = ({
  title,
  fields,
  shouldShowPreview,
}: EintragBearbeitenExpansionPanelVorschauProps) => {
  const preview = generatePreview(fields);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="k-expander-title" style={{ minWidth: "fit-content" }}>
          {title}
        </div>
        {shouldShowPreview && <span className="Textvorschau">{preview}</span>}
      </div>
    </>
  );
};

export default EintragBearbeitenExpansionPanelVorschau;

function generatePreview(fields: IField[]) {
  const ohneInhaltText = "(Ohne Inhalt)";
  // case there are no fields
  if (fields.length === 0) return ohneInhaltText;

  // case all fields are empty -> return ohneInhaltText
  let emptyFields = fields.filter((field) => {
    return (
      field.value === "" || field.value === undefined || field.value === null
    );
  });

  if (emptyFields.length === fields.length) return ohneInhaltText;

  //in case input is empty, then field should not be displayed (neither label nor value)
  let nonEmptyFields = fields.filter((field) => {
    return (
      field.value !== "" && field.value !== undefined && field.value !== null
    );
  });

  let convertetFields = nonEmptyFields.map((field) => {
    if (typeof field.value === "boolean") return field.label; // case it is checkbox -> only return the label
    return field.label + ": " + String(field.value);
  });
  let previewText = convertetFields.join("; ");
  return previewText;
}

import { GenericObject } from "../../../../../interfaces/GenericObject";
import { SelectedState } from "./useGridRowSelection";

export default function createInitialSelectedState(
  initialSelectedItems: GenericObject[] | undefined,
  idGetter: Function
) {
  const initialSelectedState: SelectedState = {};
  if (!initialSelectedItems) return initialSelectedState;

  for (const item of initialSelectedItems) {
    initialSelectedState[idGetter(item)] = true;
  }
  return initialSelectedState;
}

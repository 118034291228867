import { DatePicker } from "@progress/kendo-react-dateinputs";
import React from "react";
import {
  IntlProvider,
  load,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/de/numbers.json";
import caGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/de/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/de/timeZoneNames.json";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import { ABK_READ_ONLY } from "../../../theme/customClassNames";
import ABKLabel from "../ABKLabel";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);

// Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/globalization/
const language = "de-DE";

interface AbkFormFieldInputProps {
  editorId: string;
  label: string;
  value: Date | null;
  setValue: (value: Date | null) => void;
  readOnly: boolean;
}

const ABKDatePickerInput: React.FC<AbkFormFieldInputProps> = (props) => (
  <LocalizationProvider language={language}>
    <IntlProvider locale={language}>
      <ABKFormFieldInputWrapper>
        <ABKLabel editorId={props.editorId} className="k-d-flex">
          {props.label}
        </ABKLabel>
        <DatePicker
          className={props.readOnly ? ABK_READ_ONLY : ""}
          format="dd.MM.yyyy"
          id={props.editorId}
          lang={language}
          name={props.editorId}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          formatPlaceholder={{ day: "Tag", month: "Monat", year: "Jahr" }}
          placeholder={props.readOnly ? "" : undefined}
          toggleButton={props.readOnly ? () => null : undefined}
          /*
            Wenn es `readOnly` ist, wir wollen vermeiden, dass der User auf
            den DatePicker klickt, und der Placeholder zeigt.
            Wenn der "dateInput" `() => null` ist, wird kein Placeholder gezeigt,
            wenn der User auf einen `readOnly` DatePicker klickt.
          */
          dateInput={props.readOnly && !props.value ? () => null : undefined}
          adaptive={true}
        />
      </ABKFormFieldInputWrapper>
    </IntlProvider>
  </LocalizationProvider>
);

export default ABKDatePickerInput;

import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.scss";
import reportWebVitals from "./reportWebVitals";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
  LocalizationService,
} from "@progress/kendo-react-intl";
import { deMessages } from "./core/assets/messages/de";
import AppLayout from "./AppLayout";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

class CustomLocalizationService extends LocalizationService {
  toLanguageString(key: string, defaultValue: string) {
    return super.toLanguageString(key, defaultValue);
  }
}

class CustomLocalizationProvider extends LocalizationProvider {
  getLocalizationService() {
    return new CustomLocalizationService(this.props.language);
  }
}

function getIntlProviderLocale() {
  const userLocale = navigator.language;

  const availableLocales = ["de", "en"];
  const isLocaleAvailable = availableLocales.some((locale) =>
    userLocale.startsWith(locale)
  );

  /*
    Die "de-AT" `defaultLocale` wurde willkürlich ausgewählt.
    Kann auf Wunsch geändert werden.
  */
  const defaultLocale = "de-AT";
  const locale = isLocaleAvailable ? userLocale : defaultLocale;
  return locale;
}

loadMessages(deMessages, "de");

root.render(
  <React.StrictMode>
    <CustomLocalizationProvider language="de">
      {/*
        `IntlProvider` wurde hier gesetzt, um die Zahlen in den `NumericInput`
        von KendoReact im Default-Browser-Format zu formatieren.
        https://ib-data.atlassian.net/browse/ABK9-832
      */}
      <IntlProvider locale={getIntlProviderLocale()}>
        <AppLayout />
      </IntlProvider>
    </CustomLocalizationProvider>
  </React.StrictMode>
);

reportWebVitals();

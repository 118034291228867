import { useState } from "react";
import ABKListView from "../../../../../../../abk-shared/components/organisms/ABKListView";
import { useEintragBearbeitenState } from "../useEintragBearbeitenState";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import EintragBearbeitenExpansionPanelVorschau from "./EintragBearbeitenExpansionPanelVorschau";

const AuswahllistePanel = () => {
  const { pageState, setPageState } = useEintragBearbeitenState();

  const [expanded, setExpanded] = useState(false);

  const [idCounter, setIdCounter] = useState(
    (pageState?.AWList_Text?.length ?? 0) + 1
  );

  const data = pageState?.AWList_Text?.map((item) => ({
    id: item.Eintrag,
    name: item.Bezeichnung,
  }));

  const handleDataChange = (newData: any[]) => {
    const updatedAWList_Text = newData.map((item) => ({
      Eintrag: item.id,
      Bezeichnung: item.name,
    }));

    if (pageState) {
      const updatedPageState: EintragFrontend = {
        ...pageState,
        AWList_Text: updatedAWList_Text,
      };
      setPageState(updatedPageState);
    }
  };

  const handleAddItem = () => {
    const newId = `new-${idCounter}`;
    setIdCounter(idCounter + 1);
    const newItem = { id: newId, name: "" };
    const newData = [...(data || []), newItem];
    handleDataChange(newData);
  };

  return (
    <ABKControlledExpansionPanel
      title={
        <EintragBearbeitenExpansionPanelVorschau
          title="Auswahlliste"
          fields={[
            {
              label: "Anzahl",
              value: data?.length ? `${data.length} Einträge` : "0 Einträge",
            },
          ]}
          data-testid="test-expansion-panel-title"
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ABKListView
        data={data || []}
        onDataChange={handleDataChange}
        onAddItem={handleAddItem}
        addButtonText="Eintrag hinzufügen"
        itemFields={["name"]}
        footerText="Einträge"
      />
    </ABKControlledExpansionPanel>
  );
};

export default AuswahllistePanel;

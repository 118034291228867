import { createBrowserRouter, Navigate } from "react-router-dom";
import StartseiteLayout from "./Layout/Layout";
import NotFound from "./NotFound";
import DateiInfoPage from "../modules/Datei/pages/Allgemein";
import StartseiteIndex from "../modules/Startseite/pages";
import DateiLayout from "../modules/Datei/Layout";
import Cat from "../core/assets/images/cat.png";
import DateiIndexPage from "../modules/Datei";
import ModulesLayout from "../modules";
import AdminLayout from "../modules/Admin/layout";
import DatabasesPage from "../modules/Admin/pages/databases";
import HilfeLayout from "../modules/Hilfe/layout";
import UserLayout from "../modules/User/layout";
import SysInfoPage from "../modules/Hilfe/pages/SysInfoPage";
import UserProfilPage from "../modules/User/UserProfilPage";
import AppInfo from "../modules/Hilfe/pages/AppInfo";
import * as urls from "../core/constants/urls";
import DbStatusPage from "../modules/Admin/pages/DbStatusPage";
import ServerStatusPage from "../modules/Admin/pages/ServerStatusPage";
import MainPageTemplate from "../core/components/templates/MainPageTemplate";
import { RequestType } from "../modules/Startseite/hooks/useGetCont";
import TabsLayout from "./Layout/TabsLayout";
import ServerInfoPage from "../modules/Admin/pages/ServerInfoPage";
import ServerLogPage from "../modules/Admin/pages/ServerLogPage";
import VerwaltenWrapper from "../modules/Startseite/pages/Verwalten";
import ErstellenWrapper from "../modules/Startseite/pages/Erstellen/ErstellenWrapper";
import OeffnenWrapper from "../modules/Startseite/pages/OpenPage/OeffnenWrapper";
import WrapperTemplate from "../core/components/templates/WrapperTemplate";
import DbProtocolPageWrapper from "../modules/Admin/pages/DbProcotolPage";
import DateiProtocolPageWrapper from "../modules/Datei/pages/DateiProtocolPage";
import ImportWrapper from "../modules/Startseite/pages/Import";
import Aufbau from "../modules/Datei/pages/Aufbau";
import Einträge from "../modules/Datei/pages/Parameterlisten/Einträge";
import Grafiken from "../modules/Datei/pages/Grafiken";
import Piktogramme from "../modules/Datei/pages/Parameterlisten/Piktogramme";
import WeitereGliederungssysteme from "../modules/Datei/pages/WeitereGliederungssysteme";
import WeitereParameterlisten from "../modules/Datei/pages/Parameterlisten/WeitereParameterlisten";
import KenndatenWrapper from "../modules/Datei/pages/Parameterlisten/Kenndaten";
import AdminHelpPage from "../modules/Hilfe/pages/AdminHelp";
import LocalBrowserConfiguration from "../modules/User/LocalBrowserConfiguration";
import ParameterlisteLayoutWrapper from "../modules/Datei/pages/Parameterlisten/WeitereParameterlisten/ParameterlisteLayoutWrapper";

const Router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: (
          <>
            <MainPageTemplate includeTitle includeDescription />
            <Navigate to="/db/_default_" replace />
          </>
        ),
      },
      // DB Routes
      {
        path: "db/:db",
        element: <ModulesLayout />,
        children: [
          {
            index: true,
            element: (
              <>
                <WrapperTemplate includeDescription includeTitle />
                <Navigate to="start" replace />
              </>
            ),
          },
          // File Routes
          {
            path: "file",
            children: [
              { index: true, element: <DateiIndexPage /> },
              {
                path: ":contId",
                element: <DateiLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={urls.FILE_INFO_SUFFIX} replace />,
                  },
                  { path: urls.FILE_INFO_SUFFIX, element: <DateiInfoPage /> },
                  { path: urls.FILE_AUFBAU_SUFFIX, element: <Aufbau /> },
                  { path: urls.FILE_EINTRAEGE_SUFFIX, element: <Einträge /> },
                  { path: urls.FILE_GRAFIKEN_SUFFIX, element: <Grafiken /> },
                  {
                    path: urls.FILE_PIKTOGRAMME_SUFFIX,
                    element: <Piktogramme />,
                  },
                  {
                    path: urls.FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
                    element: <WeitereGliederungssysteme />,
                  },
                  {
                    path: urls.FILE_WEITEREPARAMETERLISTEN_SUFFIX,
                    children: [
                      { index: true, element: <WeitereParameterlisten /> },
                      {
                        path: ":plID",
                        element: <ParameterlisteLayoutWrapper />,
                        children: [
                          {
                            index: true,
                            element: (
                              <Navigate
                                to={urls.FILE_PL_KENNDATEN_SUFFIX}
                                replace
                              />
                            ),
                          },
                          {
                            path: urls.FILE_PL_KENNDATEN_SUFFIX,
                            element: <KenndatenWrapper />,
                            /*
                              "children" ist für die Vorversion, auskommentiert, um einen Spur für die Vorversion
                              der Gliederungssysteme zu behalten.
                              Wenn die Vorversion für die Gliederungssystem implementiert ist,
                              dann kann der folgende Code gelöscht werden, mit diesem Kommentar.
                            */
                            // children: [
                            //   {
                            //     path: FILE_KENNDATEN_VORVERSION_SUFFIX,
                            //     element: <KenndatenWrapper isPreviousVersion={true} />,
                            //   },
                            // ],
                          },
                          {
                            path: urls.FILE_PL_EINTRAEGE_SUFFIX,
                            element: <Einträge />,
                          },
                          {
                            path: urls.FILE_PIKTOGRAMME_SUFFIX,
                            element: <Piktogramme />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: urls.FILE_PROTOCOL_SUFFIX,
                    element: <DateiProtocolPageWrapper />,
                  },
                  { path: "cat", element: <img alt="cat" src={Cat} /> },
                ],
              },
            ],
          },
          // Start routes
          {
            path: "start",
            element: <StartseiteLayout />,
            children: [
              { index: true, element: <Navigate to="open" replace /> },
              {
                path: "open",
                element: (
                  <TabsLayout
                    routePrefix="open"
                    tabs={[
                      {
                        id: 0,
                        label: "Zuletzt verwendet",
                        to: `open/${urls.LAST_USED}`,
                      },
                      { id: 1, label: "Favoriten", to: `open/favorites` },
                      { id: 2, label: "Eigene", to: `open/own` },
                      { id: 3, label: "Alle", to: `open/all` },
                    ]}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.ZuletztVerwendeteContainer}
                      />
                    ),
                  },
                  {
                    path: urls.LAST_USED,
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.ZuletztVerwendeteContainer}
                      />
                    ),
                  },
                  {
                    path: "favorites",
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: "own",
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.MeineContainer}
                      />
                    ),
                  },
                  {
                    path: "all",
                    element: (
                      <OeffnenWrapper
                        containerType={RequestType.AlleContainer}
                      />
                    ),
                  },
                ],
              },
              {
                path: "new",
                element: (
                  <TabsLayout
                    routePrefix="new"
                    tabs={[
                      {
                        id: 0,
                        label: "Zuletzt verwendet",
                        to: `new/${urls.LAST_USED}`,
                      },
                      { id: 1, label: "Alle Vorlagen", to: `new/all` },
                    ]}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <ErstellenWrapper
                        containerType={RequestType.ZuletztVerwendeteVorlagen}
                      />
                    ),
                  },
                  {
                    path: urls.LAST_USED,
                    element: (
                      <ErstellenWrapper
                        containerType={RequestType.ZuletztVerwendeteVorlagen}
                      />
                    ),
                  },
                  {
                    path: "all",
                    element: (
                      <ErstellenWrapper
                        containerType={RequestType.AlleVorlagen}
                      />
                    ),
                  },
                ],
              },
              {
                path: "files",
                element: (
                  <TabsLayout
                    routePrefix="files"
                    tabs={[
                      {
                        id: 0,
                        label: "Zuletzt verwendet",
                        to: `files/${urls.LAST_USED}`,
                      },
                      { id: 1, label: "Favoriten", to: `files/favorites` },
                      { id: 2, label: "Eigene", to: `files/own` },
                      { id: 3, label: "Alle", to: `files/all` },
                    ]}
                  />
                ),
                children: [
                  {
                    index: true,
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: urls.LAST_USED,
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: "favorites",
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineFavoriten}
                      />
                    ),
                  },
                  {
                    path: "own",
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.MeineContainer}
                      />
                    ),
                  },
                  {
                    path: "all",
                    element: (
                      <VerwaltenWrapper
                        containerType={RequestType.AlleContainer}
                      />
                    ),
                  },
                ],
              },
              { path: "import", element: <ImportWrapper /> },
            ],
          },
          // Admin Routes
          {
            path: urls.ADMIN_SUFFIX,
            element: <AdminLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={urls.ADMIN_ORG_SUFFIX} replace />,
              },
              { path: urls.ADMIN_ORG_SUFFIX, element: <h1>Admin Org Page</h1> },
              {
                path: urls.ADMIN_DEPARTMENTS_SUFFIX,
                element: <h1>Admin Departments Page</h1>,
              },
              { path: urls.ADMIN_DB_STATUS_SUFFIX, element: <DbStatusPage /> },
              {
                path: urls.ADMIN_DB_PROTOCOL_SUFFIX,
                element: <DbProtocolPageWrapper />,
              },
              {
                path: urls.ADMIN_SERVER_STATUS_SUFFIX,
                element: <ServerStatusPage />,
              },
              {
                path: urls.ADMIN_SERVER_INFO_SUFFIX,
                element: <ServerInfoPage />,
              },
              {
                path: urls.ADMIN_SERVER_LOG_SUFFIX,
                element: <ServerLogPage />,
              },
              { path: urls.ADMIN_DATABASES_SUFFIX, element: <DatabasesPage /> },
              {
                path: urls.ADMIN_LICENSES_SUFFIX,
                element: <h1>Admin Lizenzen Page</h1>,
              },
              {
                path: urls.ADMIN_USERS_SUFFIX,
                element: <h1>Admin Users Page</h1>,
              },
            ],
          },
          // User Routes
          {
            path: urls.USER_SUFFIX,
            element: <UserLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={urls.USER_PROFILE_SUFFIX} replace />,
              },
              { path: urls.USER_PROFILE_SUFFIX, element: <UserProfilPage /> },
              {
                path: urls.USER_LOCAL_BROWSER_CONFIG_SUFFIX,
                element: <LocalBrowserConfiguration />,
              },
            ],
          },
        ],
      },
      // Old Routes
      {
        path: "startseite",
        element: <StartseiteLayout />,
        children: [{ index: true, element: <StartseiteIndex /> }],
      },

      // Help Routes
      {
        path: "help",
        element: <HilfeLayout />,
        children: [
          { index: true, path: urls.HELP_START_SUFFIX, element: <AppInfo /> },
          { path: urls.HELP_SYSINFO_SUFFIX, element: <SysInfoPage /> },
          {
            path: urls.HELP_ADMIN_HELP_SUFFIX,
            element: <AdminHelpPage />,
          },
        ],
      },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

export default Router;

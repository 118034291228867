import { DATA_KEY } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import {
  EintragBackend,
  EintragFrontend,
} from "../../../interfaces/parameterlisten";
import {
  booleanFieldMapper,
  booleanFieldReverseMapper,
  parTypeMapper,
  parTypeReverseMapper,
} from "./maps";

export const backendEintragToFrontend = (backendEintrag: EintragBackend) =>
  ({
    ...backendEintrag,
    ITEMTYPE: parTypeMapper.get(backendEintrag.ITEMTYPE),
    MENGENABH: booleanFieldMapper.get(backendEintrag.MENGENABH),
    FAVORIT: booleanFieldMapper.get(backendEintrag.FAVORIT),
    SUMMEBILDEN: booleanFieldMapper.get(backendEintrag.SUMMEBILDEN),
    REQUIRED: booleanFieldMapper.get(backendEintrag.REQUIRED),
    NUMMIN: createInternalValueForNumber(backendEintrag.NUMMIN),
    NUMMAX: createInternalValueForNumber(backendEintrag.NUMMAX),
  }) as EintragFrontend;

export const createInternalValueForNumber = (
  number: number | null | undefined
) => ({
  [DATA_KEY.DISPLAYED]:
    number != null ? new Intl.NumberFormat().format(number) : "",
  [DATA_KEY.INTERNAL]: number,
});

export const frontendEintragToBackend = (frontendEintrag: EintragFrontend) =>
  ({
    ...frontendEintrag,
    ITEMTYPE: parTypeReverseMapper.get(frontendEintrag.ITEMTYPE),
    MENGENABH: booleanFieldReverseMapper.get(frontendEintrag.MENGENABH),
    FAVORIT: booleanFieldReverseMapper.get(frontendEintrag.FAVORIT),
    SUMMEBILDEN: booleanFieldReverseMapper.get(frontendEintrag.SUMMEBILDEN),
    REQUIRED: booleanFieldReverseMapper.get(frontendEintrag.REQUIRED),
    NUMMIN: frontendEintrag.NUMMIN.internal,
    NUMMAX: frontendEintrag.NUMMAX.internal,
  }) as EintragBackend;

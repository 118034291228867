import { PropsCommunicationComponents } from ".";
import ABKFormFieldInput from "../../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import shouldDisplayJSXInsteadOfInput from "./shouldDisplayJSXInsteadOfInput";

export default function Email({
  value,
  readOnly,
  changeValue,
}: PropsCommunicationComponents) {
  const useJSXForValue = shouldDisplayJSXInsteadOfInput(value, readOnly);
  const emailFormatted = useJSXForValue ? (
    <a className="kenndaten-link" href={`mailto:${value}`}>
      {value}
    </a>
  ) : (
    value
  );

  const setEmail = readOnly
    ? undefined
    : (value: string) => changeValue("email", value);

  return (
    <ABKFormFieldInput
      editorId="email"
      label="E-Mail"
      value={emailFormatted}
      setValue={setEmail}
      useJSXForValue={useJSXForValue}
    />
  );
}

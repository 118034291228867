import { Button } from "@progress/kendo-react-buttons";

const ListFooter = ({
  footerText,
  onAddItem,
  addButtonText,
}: {
  footerText?: string;
  onAddItem: () => void;
  addButtonText: string;
}) => {
  return (
    <div
      style={{
        color: "rgb(160, 160, 160)",
        fontSize: 14,
        padding: "10px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {footerText && <span>{footerText}</span>}
      <Button onClick={onAddItem}>{addButtonText}</Button>
    </div>
  );
};

export default ListFooter;

import {
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import React from "react";
import { DataResult } from "@progress/kendo-data-query";
import { setExpandedState } from "@progress/kendo-react-data-tools";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import createPersistedDataStateStore from "./createPersistedDataStateStore";
import createPersistedCollapsedStateStore from "./createPersistedCollapsedStateStore";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import useFieldsWithInternalValue from "../useFieldsWithInternalValue";
import useFieldsFiltered from "./useFieldsFiltered";
import {
  createActiveColumnMenus,
  createNewFilterState,
  generateFiltersToMatchDisplayedValue,
  processAndSetGroupIds,
} from "./processData";

function useGridDataState(
  data: GenericObject[],
  columns: GridColumnPropsCustom[],
  gridId: string,
  updateSelectedStateAfterFilter: (dataResult: DataResult) => void
) {
  // From https://github.com/pmndrs/zustand/blob/main/docs/guides/initialize-state-with-props.md#basic-component-usage
  const dataStateStore = React.useRef(
    createPersistedDataStateStore(gridId)
  ).current;
  const { dataState, setDataState } = dataStateStore();

  const [filterValue, setFilterValue] = React.useState("");

  const fieldsWithInternalValue = useFieldsWithInternalValue(columns);
  const [dataResult, setDataResult] = React.useState<DataResult>(() =>
    processAndSetGroupIds(data, dataState, fieldsWithInternalValue)
  );

  // From https://github.com/pmndrs/zustand/blob/main/docs/guides/initialize-state-with-props.md#basic-component-usage
  const collapsedStateStore = React.useRef(
    createPersistedCollapsedStateStore(gridId)
  ).current;
  const { collapsedState, setCollapsedState } = collapsedStateStore();

  React.useEffect(() => {
    const dataResult = processAndSetGroupIds(
      data,
      dataState,
      fieldsWithInternalValue
    );
    setDataResult(dataResult);
  }, [data]);

  const onDataStateChange = React.useCallback(
    (event: GridDataStateChangeEvent) => {
      const newDataState = event.dataState;

      setDataState({
        take: newDataState.take,
        filter: newDataState.filter,
        skip: newDataState.skip,
        sort: newDataState.sort,
        group: newDataState.group,
      });

      const isScrollEvent =
        event.nativeEvent && event.nativeEvent.type === "scroll";
      if (isScrollEvent) return;

      const newDataResult = processAndSetGroupIds(
        data,
        newDataState,
        fieldsWithInternalValue
      );
      setDataResult(newDataResult);
    },
    [data, fieldsWithInternalValue]
  );

  const onExpandChange = React.useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem;

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  const fieldsFiltered = useFieldsFiltered(columns);

  const onFilterChange = React.useCallback(
    (newValue: string) => {
      const newFilterValue = newValue;

      const filtersToMatchSearch = generateFiltersToMatchDisplayedValue(
        fieldsFiltered,
        fieldsWithInternalValue,
        newFilterValue
      );
      const newFilterState = createNewFilterState(
        dataState.filter,
        filtersToMatchSearch
      );

      /*
        Wir müssen das `skip` zurücksetzen, ansonsten werden die Items aktualisiert
        aber nicht korrekt angezeigt. Das kann sein, dass ohne `skip = 0` nichts
        im Grid angezeigt wird.
      */
      dataState.skip = 0;
      setDataState({ ...dataState, filter: newFilterState });
      setFilterValue(newFilterValue);

      dataState.filter = newFilterState;
      const newDataResult = processAndSetGroupIds(
        data,
        dataState,
        fieldsWithInternalValue
      );
      setDataResult(newDataResult);

      updateSelectedStateAfterFilter(newDataResult);
    },
    [
      data,
      dataState,
      updateSelectedStateAfterFilter,
      fieldsWithInternalValue,
      fieldsFiltered,
    ]
  );

  const filter = dataState.filter;
  const isColumnMenuActive = React.useCallback(
    (columnField: string | undefined) => {
      if (!columnField || !filter) return false;

      const activeColumnMenus = filter.filters.reduce(
        createActiveColumnMenus,
        {} as Record<string, true>
      );

      const isActive = activeColumnMenus[columnField] === true;
      return isActive;
    },
    [filter]
  );

  const dataWithExpanded = setExpandedState({
    data: dataResult.data,
    collapsedIds: collapsedState,
  });
  const dataResultWithExpanded = {
    data: dataWithExpanded,
    total: dataResult.total,
  };

  return {
    dataState,
    onDataStateChange,
    onExpandChange,
    onFilterChange,
    filterValue,
    dataResult: dataResultWithExpanded,
    isColumnMenuActive,
  };
}

export default useGridDataState;

import AbkOenormEditor from "../../../../../../../core/components/organisms/AbkOenormEditor";
import editorTools from "../../../../../../../core/components/organisms/AbkOenormEditor/editorTools";
import CustomViewHtmlHook from "../../../../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog/CustomViewHtmlHook";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import { useEintragBearbeitenState } from "../useEintragBearbeitenState";
import ABKExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKExpansionPanel";

const KommentarPanel = () => {
  const { pageState, setPageState } = useEintragBearbeitenState();
  return (
    <ABKExpansionPanel title="Kommentar" key="kommentarPanel">
      <AbkOenormEditor
        tools={[
          ...editorTools.oenormTools,
          [CustomViewHtmlHook],
          ...editorTools.extraTools,
          ...editorTools.oenormTabelleTools,
          ...editorTools.tableTools,
        ]}
        value={pageState?.KOMMENTAR ?? ""}
        setValue={(value: string) => {
          setPageState({ ...pageState, KOMMENTAR: value } as EintragFrontend);
        }}
      />
    </ABKExpansionPanel>
  );
};

export default KommentarPanel;

import { PropsCommunicationComponents } from ".";
import ABKFormFieldInput from "../../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import shouldDisplayJSXInsteadOfInput from "./shouldDisplayJSXInsteadOfInput";

export default function Phone({
  value,
  readOnly,
  changeValue,
}: PropsCommunicationComponents) {
  const useJSXForValue = shouldDisplayJSXInsteadOfInput(value, readOnly);
  const phoneFormatted = useJSXForValue ? (
    <a className="kenndaten-link" href={`tel:${value}`}>
      {value}
    </a>
  ) : (
    value
  );

  const setPhone = readOnly
    ? undefined
    : (value: string) => changeValue("telefon", value);

  return (
    <ABKFormFieldInput
      editorId="telefon"
      label="Telefon"
      value={phoneFormatted}
      setValue={setPhone}
      useJSXForValue={useJSXForValue}
    />
  );
}

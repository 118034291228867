import ABKFormFieldInput from "../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import addHttpToLinkIfNotPresent from "../addHttpToLinkIfNotPresent";
import { AllgemeinFieldInputProps } from "./AllgemeinForm";

export default function DownloadLink({
  pageState,
  setPageState,
  kenndaten,
  readOnly,
}: AllgemeinFieldInputProps) {
  const value = readOnly ? (
    <a
      className="kenndaten-link"
      href={addHttpToLinkIfNotPresent(kenndaten.DLURL)}
      target="_blank"
      rel="noreferrer"
    >
      {kenndaten.DLURL}
    </a>
  ) : (
    kenndaten.DLURL || ""
  );

  const setValue = readOnly
    ? undefined
    : (value: string) =>
        setPageState({
          ...pageState,
          Kenndaten: {
            ...kenndaten,
            DLURL: value,
          },
        });

  return (
    <ABKFormFieldInput
      editorId="downloadlink"
      label="Downloadlink"
      value={value}
      setValue={setValue}
      useJSXForValue={readOnly}
    />
  );
}

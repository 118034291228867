import { Loader } from "@progress/kendo-react-indicators";
import useGetSingleParameterliste from "../../../queries/useGetSingleParameterliste";

type Props = { db: string; contId: string; plID: string };
const PiktogrammePage = ({ db, contId, plID }: Props) => {
  const {
    data: parameterliste,
    isLoading,
    error: parameterlisteLoadingError,
  } = useGetSingleParameterliste(db, contId, plID!);

  if (isLoading || !parameterliste) return <Loader />;

  if (parameterlisteLoadingError)
    return <div>{parameterlisteLoadingError.message}</div>;

  return (
    <p>
      Piktogramme von PL: {parameterliste?.value.DATAID} im container{" "}
      {parameterliste?.value.CONTID}
    </p>
  );
};

export default PiktogrammePage;

import React from "react";
import { KEY_REMOVE_FROM_FILTERING } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";
import { PropsCustomCell } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  booleanFieldReverseMapper,
  hideParMapper,
  parTypeMapper,
} from "./maps";
import {
  AWList_Text,
  EintragCheckboxValue,
  EintragFrontend,
} from "../../../interfaces/parameterlisten";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";
import { CreateGridActionsFunction } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridAction";
import HighlightedCell from "../../../../../abk-shared/components/organisms/ABKBasisGrid/components/HighlightedCell";

const useEintraege = () => {
  const { setCurrentEintrag, setOpenDialog, setShowDeleteDialog } =
    useEintragBearbeitenState();

  const columnsDefinition = React.useMemo(() => {
    const columns = [
      {
        field: "Allgemein",
        title: "Allgemein",
        orderIndex: 1,
        minWidth: 100,
        children: [
          {
            field: "VARNAME",
            title: "Parameterkennung",
            orderIndex: 2,
            minWidth: 100,
          },
          {
            field: "ITEMTYPE",
            title: "Kategorie",
            orderIndex: 3,
            minWidth: 100,
          },
          {
            field: "MENGENABH",
            title: "Mengenabhängig",
            orderIndex: 4,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "BEZ",
            title: "Bezeichnung",
            orderIndex: 5,
            minWidth: 100,
          },
          {
            field: "EH",
            title: "Einheit",
            orderIndex: 6,
            minWidth: 100,
          },
          {
            field: "FAVORIT",
            title: "Favorit",
            orderIndex: 7,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "SHOWIF",
            title: "Ausblenden",
            show: false,
            orderIndex: 8,
            minWidth: 100,
          },
          {
            field: "SUMMEBILDEN",
            title: "Summe bilden",
            orderIndex: 9,
            minWidth: 100,
          },
          {
            field: "REQUIRED",
            title: "Erforderlich",
            orderIndex: 10,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "ITEMID",
            title: "Parameter-ID",
            show: false,
            orderIndex: 11,
            minWidth: 100,
          },
          {
            field: "Funktion",
            title: "Funktion",
            show: false,
            orderIndex: 12,
            width: 100,
          },
          {
            field: "Produkt",
            title: "Produktgruppe",
            show: false,
            orderIndex: 13,
            width: 100,
          },
          {
            field: "PIKTID",
            title: "Piktogramm-ID",
            show: false,
            orderIndex: 14,
            width: 100,
          },
        ],
      },
      {
        field: "Zahl",
        title: "Zahl",
        orderIndex: 18,
        show: false,
        minWidth: 100,
        children: [
          {
            field: "NUMNK",
            title: "Nachkommastellen",
            orderIndex: 19,
            minWidth: 100,
            show: false,
          },
          {
            field: "NUMMIN",
            title: "Minimalwert",
            orderIndex: 20,
            minWidth: 100,
            show: false,
            hasInternalValue: true,
          },
          {
            field: "NUMMAX",
            title: "Maximalwert",
            orderIndex: 21,
            minWidth: 100,
            show: false,
            hasInternalValue: true,
          },
          {
            field: "FORMEL",
            title: "Rechenansatz",
            orderIndex: 22,
            show: false,
          },
          {
            field: "AWList_Num",
            title: "Auswahlliste bei Typ Zahl",
            orderIndex: 23,
            width: 100,
            show: false,
          },
        ],
      },
      {
        field: "Text",
        title: "Text",
        show: false,
        orderIndex: 24,
        children: [
          {
            field: "AWList_Text",
            title: "Auswahlliste bei Typ Text einzeilig",
            orderIndex: 25,
            width: 100,
            show: false,
          },
        ],
      },
      {
        field: "Sonstige",
        title: "Sonstige",
        show: false,
        orderIndex: 26,
        width: 100,
        children: [
          {
            field: "AWUSEREN",
            title: "Benutzereingabe für Auswahlliste",
            orderIndex: 27,
            width: 100,
            show: false,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
        ],
      },
    ] as GridColumnPropsCustom[];

    const version = 3;

    const migrate: GridColumnsMigrateFunction = (persistedColumns, version) => {
      if (version === 1) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        // Children wurde in Version 2 hinzugefügt
        const firstColumn = newPersistedColumns.columns[0];
        firstColumn.field = "Allgemein";
        firstColumn.title = "Allgemein";
        firstColumn.children = [
          {
            field: "VARNAME",
            title: "Parameterkennung",
            orderIndex: 2,
            minWidth: 100,
          },
          {
            field: "ITEMTYPE",
            title: "Kategorie",
            orderIndex: 3,
            minWidth: 100,
          },
          {
            field: "MENGENABH",
            title: "Mengenabhängig",
            orderIndex: 4,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "BEZ",
            title: "Bezeichnung",
            orderIndex: 5,
            minWidth: 100,
          },
          {
            field: "EH",
            title: "Einheit",
            orderIndex: 6,
            minWidth: 100,
          },
          {
            field: "FAVORIT",
            title: "Favorit",
            orderIndex: 7,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "SHOWIF",
            title: "Ausblenden",
            show: false,
            orderIndex: 8,
            minWidth: 100,
          },
          {
            field: "SUMMEBILDEN",
            title: "Summe bilden",
            orderIndex: 9,
            minWidth: 100,
          },
          {
            field: "REQUIRED",
            title: "Erforderlich",
            orderIndex: 10,
            minWidth: 100,
            [KEY_REMOVE_FROM_FILTERING]: true,
          },
          {
            field: "ITEMID",
            title: "Parameter-ID",
            show: false,
            orderIndex: 11,
            minWidth: 100,
          },
          {
            field: "Funktion",
            title: "Funktion",
            show: false,
            orderIndex: 12,
            width: 100,
          },
          {
            field: "Produkt",
            title: "Produktgruppe",
            show: false,
            orderIndex: 13,
            width: 100,
          },
          {
            field: "PIKTID",
            title: "Piktogramm-ID",
            show: false,
            orderIndex: 14,
            width: 100,
          },
        ] as GridColumnPropsCustom[];

        newPersistedColumns.columns.push({
          field: "Zahl",
          title: "Zahl",
          orderIndex: 18,
          show: false,
          minWidth: 100,
          children: [
            {
              field: "NUMNK",
              title: "Nachkommastellen",
              orderIndex: 19,
              minWidth: 100,
            },
            {
              field: "NUMMIN",
              title: "Minimalwert",
              orderIndex: 20,
              minWidth: 100,
            },
            {
              field: "NUMMAX",
              title: "Maximalwert",
              orderIndex: 21,
              minWidth: 100,
            },
            {
              field: "FORMEL",
              title: "Rechenansatz",
              orderIndex: 22,
            },
            {
              field: "AWList_Num",
              title: "Auswahlliste bei Typ Zahl",
              orderIndex: 23,
              width: 100,
            },
          ] as GridColumnPropsCustom[],
        });

        newPersistedColumns.columns.push({
          field: "Text",
          title: "Text",
          show: false,
          orderIndex: 24,
          children: [
            {
              field: "AWList_Text",
              title: "Auswahlliste bei Typ Text einzeilig",
              orderIndex: 25,
              width: 100,
            },
          ],
        });

        newPersistedColumns.columns.push({
          field: "Sonstige",
          title: "Sonstige",
          show: false,
          orderIndex: 26,
          width: 100,
          children: [
            {
              field: "AWUSEREN",
              title: "Benutzereingabe für Auswahlliste",
              orderIndex: 27,
              width: 100,
            },
          ],
        });

        return newPersistedColumns;
      }

      if (version === 2) {
        const newPersistedColumns = persistedColumns as PersistedColumns;

        /*
          Benutze `hasInternalValue`, um das Default-Browser-Format für die
          Zahlen anzuzeigen
        */
        const columnZahl = newPersistedColumns.columns[1];
        const childrenZahl = columnZahl.children as GridColumnPropsCustom[];

        const columnNUMMIN = childrenZahl[1];
        columnNUMMIN.hasInternalValue = true;

        const columnNUMMAX = childrenZahl[2];
        columnNUMMAX.hasInternalValue = true;

        // Füge den Key hinzu, um einen Bug mit dem Filtering zu beheben
        const columnSonstiges = newPersistedColumns.columns[3];
        const columnBenutzereingabe = (
          columnSonstiges.children as GridColumnPropsCustom[]
        )[0];
        columnBenutzereingabe[KEY_REMOVE_FROM_FILTERING] = true;

        return newPersistedColumns as PersistedColumns;
      }

      return persistedColumns as PersistedColumns;
    };

    return { columns, version, migrate };
  }, []);

  const customCells = React.useMemo(
    () => [
      {
        columnField: "REQUIRED",
        render: (props: PropsCustomCell) => (
          <GridCheckBox
            tdProps={props.tdProps}
            booleanValue={props.dataItem.REQUIRED}
          />
        ),
      },
      {
        columnField: "FAVORIT",
        render: (props: PropsCustomCell) => (
          <GridCheckBox
            tdProps={props.tdProps}
            booleanValue={props.dataItem.FAVORIT}
          />
        ),
      },
      {
        columnField: "MENGENABH",
        render: (props: PropsCustomCell) => (
          <GridCheckBox
            tdProps={props.tdProps}
            booleanValue={props.dataItem.MENGENABH}
          />
        ),
      },
      {
        columnField: "BEZ",
        render: (props: PropsCustomCell) => (
          <BoldText text={props.dataItem.BEZ} props={props} />
        ),
      },
      {
        columnField: "ITEMTYPE",
        render: (props: PropsCustomCell) => (
          <BoldText text={props.dataItem.ITEMTYPE} props={props} />
        ),
      },
      {
        columnField: "VARNAME",
        render: (props: PropsCustomCell) => (
          <BoldText text={props.dataItem.VARNAME} props={props} />
        ),
      },
      {
        columnField: "EH",
        render: (props: PropsCustomCell) => (
          <BoldText text={props.dataItem.EH} props={props} />
        ),
      },
      {
        columnField: "SUMMEBILDEN",
        render: (props: PropsCustomCell) => (
          <GridCheckBox
            tdProps={props.tdProps}
            booleanValue={props.dataItem.SUMMEBILDEN}
          />
        ),
      },
      {
        columnField: "SHOWIF",
        render: (props: PropsCustomCell) => (
          <BoldText
            text={hideParMapper.get(props.dataItem.SHOWIF) || ""}
            props={props}
          />
        ),
      },
      {
        columnField: "ITEMID",
        render: (props: PropsCustomCell) => (
          <BoldText
            text={hideParMapper.get(props.dataItem.ITEMID) || ""}
            props={props}
          />
        ),
      },
      {
        columnField: "Funktion",
        render: (props: PropsCustomCell) => (
          <>
            {props.dataItem.Funktion && (
              <BoldText
                text={props.dataItem.Funktion.map((f: string) => f).join(", ")}
                props={props}
              />
            )}
          </>
        ),
      },
      {
        columnField: "Produkt",
        render: (props: PropsCustomCell) => (
          <>
            {props.dataItem.Produkt && (
              <BoldText
                text={props.dataItem.Produkt.map((f: string) => f).join(", ")}
                props={props}
              />
            )}
          </>
        ),
      },
      {
        columnField: "AWList_Num",
        render: (props: PropsCustomCell) => (
          <BoldText
            text={
              props.dataItem.AWList_Num &&
              props.dataItem.AWList_Num.map((n: number) => n).join("; ")
            }
            props={props}
          />
        ),
      },
      {
        columnField: "AWList_Text",
        render: (props: PropsCustomCell) => (
          <BoldText
            text={
              props.dataItem.AWList_Text &&
              props.dataItem.AWList_Text.map(
                (t: AWList_Text) =>
                  `${t.Eintrag} ${t.Bezeichnung ? `(${t.Bezeichnung})` : ""}`
              ).join("; ")
            }
            props={props}
          />
        ),
      },
      {
        columnField: "FORMEL",
        render: (props: PropsCustomCell) => (
          <BoldText text={props.dataItem.FORMEL} props={props} />
        ),
      },
      {
        columnField: "AWUSEREN",
        render: (props: PropsCustomCell) => (
          <GridCheckBox
            tdProps={props.tdProps}
            booleanValue={props.dataItem.AWUSEREN}
          />
        ),
      },
    ],
    []
  );

  const eintragActionHandlers = React.useMemo(
    () => ({
      open: (_event: any, selectedEintraege: EintragFrontend[]) => {
        const eintragSelected = selectedEintraege[0];
        setCurrentEintrag(eintragSelected);
        setOpenDialog(true);
      },
      delete: async (_event: any, selectedEintraege: EintragFrontend[]) => {
        setShowDeleteDialog(true);
      },
    }),
    [setCurrentEintrag, setOpenDialog, setShowDeleteDialog]
  );

  const gridActions: CreateGridActionsFunction = React.useCallback(
    (selectedItems: any[]) => {
      const numberOfSelectedItems = selectedItems.length;

      const defaultActions = [
        {
          icon: "Edit.Edit",
          text: "Bearbeiten",
          onClick: (event: any) =>
            eintragActionHandlers.open(event, selectedItems),
        },
        {
          icon: "Grid.InsertEnd",
          text: "Hinzufügen am Tabellenende",
          onClick: () => alert("Hinzufügen am Tabellenende"),
        },
        {
          icon: "Grid.InsertBefore",
          text: "Hinzufügen vor aktueller Zeile",
          onClick: () => alert("Oberhalb Einfügen"),
        },
        {
          icon: "Edit.Delete",
          text: "Löschen",
          onClick: (event: any) =>
            eintragActionHandlers.delete(event, selectedItems),
        },
        {
          icon: "Grid.MoveDown",
          text: "Nach unten verschieben",
          onClick: () => alert("Nach unten verschieben"),
        },
        {
          icon: "Grid.MoveUp",
          text: "Nach oben verschieben",
          onClick: () => alert("Nach oben verschieben"),
        },
      ];

      if (numberOfSelectedItems === 1) {
        return [...defaultActions];
      }
      if (numberOfSelectedItems > 1) {
        const filterTexts = [
          "Bearbeiten",
          "Nach unten verschieben",
          "Nach oben verschieben",
          "Hinzufügen vor aktueller Zeile",
        ];
        const filteredActions = defaultActions.filter(
          (action) => !filterTexts.includes(action.text)
        );
        return [
          <>{`${numberOfSelectedItems} ausgewählt`}</>,
          ...filteredActions,
        ];
      }

      return defaultActions.filter((action) =>
        action.text.includes("Hinzufügen am Tabellenende")
      );
    },
    [eintragActionHandlers]
  );

  return {
    columnsDefinition,
    customCells,
    gridActions,
  };
};

export default useEintraege;

const BoldText = ({
  text,
  props,
}: {
  text: string;
  props: PropsCustomCell;
}) => {
  const isBold = props.dataItem.ITEMTYPE === parTypeMapper.get("Set");
  const isGreen = props.dataItem.ITEMTYPE === parTypeMapper.get("Head");

  const { currentEintrag } = useEintragBearbeitenState();
  const isSelected = props.dataItem.ITEMID === currentEintrag?.ITEMID;

  const isSelectedStyle = {
    color: "#464600",
    fontWeight: "bold",
    fontStyle: "italic",
  };

  return (
    <HighlightedCell {...props}>
      <span
        className="k-text-ellipsis"
        title={text}
        style={{
          fontWeight: isBold || isGreen ? "bold" : "normal",
          color: isGreen ? "#999900" : "black",
        }}
      >
        <span style={isSelected ? isSelectedStyle : {}}>{text}</span>
      </span>
    </HighlightedCell>
  );
};

const GridCheckBox = ({
  tdProps,
  booleanValue,
}: {
  tdProps: any;
  booleanValue: EintragCheckboxValue;
}) => {
  const isChecked = booleanFieldReverseMapper.get(booleanValue);
  return <td {...tdProps}>{isChecked && <Checkbox value={isChecked} />}</td>;
};

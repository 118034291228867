import { Loader } from "@progress/kendo-react-indicators";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { EintragBackend } from "../../../interfaces/parameterlisten";
import PLEintraegeGrid from "./PLEintraegeGrid";
import useInitiatePageWithContainerData from "../../useInitiatePageWithContainerData";
import EintragBearbeitenDialog from "./EintragBearbeitenDialog";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";
import { useParams } from "react-router-dom";
import React from "react";
import { backendEintragToFrontend } from "./convertBackendEintrag";

type Props = { db: string; contId: string };
const EintraegePage = ({ db, contId }: Props) => {
  const { openDialog, setOpenDialog } = useEintragBearbeitenState();

  const { plID } = useParams();

  useInitiatePageWithContainerData(db, contId);

  const { data, isLoading, isError, error, isFetching } = useQuery({
    queryKey: ["eintraege", contId, plID],
    queryFn: async () => {
      const url = `/api/db/${db}/cont/${contId}/pl/${plID}/items`;
      const response = await axios.get(url);
      const eintraege = response.data.Items as EintragBackend[];
      if (eintraege) return eintraege;
      else return [];
    },
  });

  const dataFrontend = React.useMemo(
    () => data?.map(backendEintragToFrontend),
    [data]
  );

  if (isLoading || !dataFrontend) return <Loader />;

  if (isError) return <div>{error.message}</div>;

  return (
    <>
      <PLEintraegeGrid eintraege={dataFrontend} isFetching={isFetching} />
      {openDialog && (
        <EintragBearbeitenDialog onClose={() => setOpenDialog(false)} />
      )}
    </>
  );
};

export default EintraegePage;

import { SvgIcon } from "@progress/kendo-react-common";
import * as Icons from "../../../interfaces/abk9SvgIcons";
import ABKFormFieldInput from "../../atoms/ABKFormFieldInput";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

const ABKListViewItem = ({
  id,
  name,
  onDelete,
  onEdit,
}: {
  id: string;
  name: string;
  onDelete: (id: string) => void;
  onEdit: (id: string, newName: string) => void;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const [value, setValue] = React.useState(name);
  const [isEditing, setIsEditing] = React.useState(name === "");

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: 10,
    borderBottom: "1px solid lightgrey",
    backgroundColor: isDragging ? "lightgrey" : "white",
    display: "flex",
    alignItems: "center",
  };

  const handleDelete = () => {
    onDelete(id);
  };

  const handleNameChange = (value: string) => {
    setValue(value);
    onEdit(id, value);
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div
        style={{ flex: 1 }}
        {...(!isEditing ? { ...attributes, ...listeners } : {})}
      >
        <ABKFormFieldInput
          value={value}
          editorId={id}
          setValue={isEditing ? handleNameChange : undefined}
        />
      </div>
      {!isEditing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            cursor: "pointer",
          }}
        >
          <SvgIcon
            height={16}
            width={16}
            icon={Icons.Edit_Edit}
            onClick={handleEdit}
          />
          <SvgIcon
            height={16}
            width={16}
            icon={Icons.Edit_Delete}
            onClick={handleDelete}
          />
        </div>
      ) : (
        <SvgIcon
          style={{ cursor: "pointer" }}
          height={16}
          width={16}
          icon={Icons.Edit_Post}
          onClick={handleEdit}
        />
      )}
    </div>
  );
};

export default ABKListViewItem;

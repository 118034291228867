import { Loader } from "@progress/kendo-react-indicators";
import { RequestType } from "../../hooks/useGetCont";
import useVerwalten from "./useVerwalten";
import ABKBasisGrid from "../../../../abk-shared/components/organisms/ABKBasisGrid";
import ExportDialog, { ExportDialogResponse } from "./ExportDialog";
import DeleteDialog, { DeleteDialogResponse } from "./DeleteDialog";
import React from "react";
import CardElement from "../../components/molecules/DateienCard";
import { CONTAINER_CARD_BUTTON } from "../OpenPage/Oeffnen";
import useOnClickContainerCardButton from "../OpenPage/useOnClickContainerCardButton";
import { ContWithLastChangedModified } from "../../../../core/interfaces/Cont";

type Props = {
  containerType: RequestType;
  db: string;
};

function Verwalten({ containerType, db }: Props) {
  const [exportDialogPromise, setExportDialogPromise] = React.useState<{
    resolve: (value: ExportDialogResponse) => void;
  } | null>(null);
  const [deleteDialogPromise, setDeleteDialogPromise] = React.useState<{
    resolve: (value: DeleteDialogResponse) => void;
  } | null>(null);

  const onCardClick = useOnClickContainerCardButton(db);

  const verwalten = useVerwalten(
    db,
    containerType,
    setExportDialogPromise,
    setDeleteDialogPromise
  );
  if (verwalten.shouldReturnLoader) return <Loader />;

  const containerListe =
    containerType === RequestType.MeineFavoriten
      ? verwalten.containers.filter((data) => data.OWNER === "")
      : verwalten.containers;

  return (
    <>
      <ABKBasisGrid
        data={containerListe}
        columnsDefinition={verwalten.columnsDefinition}
        gridId="verwalten"
        dataItemKey="CONTID"
        // `as any` ist ein Workaround, vielleicht besser den Typ-Fehler zu beheben
        gridActions={verwalten.gridActions as any}
        customCells={verwalten.customCells}
        onSelectionChange={verwalten.onSelectionChange}
        isLoading={verwalten.isGridLoading}
        mobileGridRow={(
          rowRef,
          item,
          filterValue,
          isSelected,
          setDataItemSelected
        ) => (
          <CardElement
            data={item as ContWithLastChangedModified}
            onCardClick={onCardClick}
            isTemplate={item.SPECIALFILE === "vorl"}
            buttonText={CONTAINER_CARD_BUTTON.TEXT}
            buttonIcon={CONTAINER_CARD_BUTTON.ICON}
            filterValue={filterValue}
            shouldDisplayOwner={true}
            isSelected={isSelected}
            setDataItemSelected={setDataItemSelected}
            rowRef={rowRef}
            hasPaddingBottom={true}
            dataTestid="mobile-grid-row"
          />
        )}
      />
      {verwalten.isExportDialogOpen && (
        <ExportDialog
          toggleDialog={verwalten.toggleExportDialog}
          selectedContainers={verwalten.selectedContainers}
          exportDialogPromise={exportDialogPromise}
        />
      )}
      {verwalten.isDeleteDialogOpen && (
        <DeleteDialog
          toggleDialog={verwalten.toggleDeleteDialog}
          selectedContainers={verwalten.selectedContainers}
          deleteDialogPromise={deleteDialogPromise}
        />
      )}
    </>
  );
}

export { Verwalten };

import useGetAllContainerParameterlisten from "../../../queries/useGetAllContainerParameterlisten";
import PLGrid from "./PLGrid";

const ParameterListen = ({
  dbName,
  contId,
}: {
  dbName: string;
  contId: string;
}) => {
  const { data: parameterlisten, isLoading } =
    useGetAllContainerParameterlisten(dbName, contId);

  if (isLoading) return <div>Loading...</div>;

  return <PLGrid parameterlisten={parameterlisten ?? []} />;
};

export default ParameterListen;

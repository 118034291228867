import { ReactNode } from "react";
import AllgemeinPanel from "./AllgemeinPanel";
import KommentarPanel from "./KommentarPanel";
import AuswahllistePanel from "./AuswahllistePanel";
import ZuordnungFunktionenPanel from "./ZuordnungFunktionenPanel";
import ZuordnungProduktgruppenPanel from "./ZuordnungProduktgruppenPanel";
import ZahlPanel from "./ZahlPanel";
import ParameterPanel from "./ParameterPanel";
import RechenansatzPanel from "./Rechenansatz";
import { ItemTypeDisplayed } from "../../maps";

export const parTypePanelsMap: Map<ItemTypeDisplayed, ReactNode[]> = new Map([
  ["Überschrift", [<AllgemeinPanel />, <KommentarPanel />]],
  [
    "Parameterset",
    [
      <AllgemeinPanel />,
      <KommentarPanel />,
      <ZuordnungFunktionenPanel />,
      <ZuordnungProduktgruppenPanel />,
    ],
  ],
  [
    "Zahl",
    [
      <AllgemeinPanel />,
      <ParameterPanel />,
      <ZahlPanel />,
      <RechenansatzPanel />,
      <AuswahllistePanel />,
      <KommentarPanel />,
    ],
  ],
  [
    "Text (einzeilig)",
    [
      <AllgemeinPanel />,
      <ParameterPanel />,
      <AuswahllistePanel />,
      <KommentarPanel />,
    ],
  ],
  [
    "Text (mehrzeilig)",
    [<AllgemeinPanel />, <ParameterPanel />, <KommentarPanel />],
  ],
  ["Datum", [<AllgemeinPanel />, <ParameterPanel />, <KommentarPanel />]],
  ["Ja/Nein", [<AllgemeinPanel />, <ParameterPanel />, <KommentarPanel />]],
]);

import { useMutation } from "@tanstack/react-query";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { useEintragBearbeitenState } from "./useEintragBearbeitenState";
import _ from "lodash";
import {
  backendEintragToFrontend,
  frontendEintragToBackend,
} from "../convertBackendEintrag";

const useSaveDataItem = ({
  itemId,
  dbname,
  contid,
  dataid,
  eintrag,
}: {
  itemId: string;
  dbname: string;
  contid: string;
  dataid: string;
  eintrag: EintragFrontend;
}) => {
  const queryClient = useQueryClient();
  const { setCurrentEintrag } = useEintragBearbeitenState();
  const fetchDataItem = async (itemId: string, eintrag: EintragFrontend) => {
    const eintragForBackend = frontendEintragToBackend(eintrag);
    const response = await axios.put(
      `/api/db/${dbname}/cont/${contid}/pl/${dataid}/items/${itemId}`,
      eintragForBackend,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  return useMutation({
    mutationKey: ["saveDataItem"],
    mutationFn: () => fetchDataItem(itemId, eintrag),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["eintraege", contid, dataid],
      });

      const dataProcessed = backendEintragToFrontend(data);
      setCurrentEintrag(_.cloneDeep(dataProcessed));
    },
  });
};

export default useSaveDataItem;

import { Link } from "react-router-dom";
import { TestProps } from "../../../../../interfaces/TestProps";
import ABKBasisGridHighlighter from "../ABKBasisGridHighlighter";
import { PropsCustomCell } from "../CustomCell";
import "./style.css";

type Props = PropsCustomCell & {
  text: string;
  to: string;
  testProps?: TestProps;
};
export default function ABKBasisGridCellLink(props: Props) {
  return (
    <td
      /*
        Not all "tdProps" are passed, to disable the default onClick event for
        the row, and allow the user to click on the link.
      */
      onContextMenu={props.tdProps?.onContextMenu}
      // Remove the default padding, the Link inside the cell will take all the space
      style={{ ...props.tdProps?.style, padding: 0 }}
    >
      <span data-testid={props.testProps?.dataTestid}>
        <Link className="link k-d-block k-text-ellipsis" to={props.to}>
          <ABKBasisGridHighlighter
            filterValue={props.filterValue}
            node={props.text}
          />
        </Link>
      </span>
    </td>
  );
}

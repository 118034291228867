import { create } from "zustand";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import axios from "axios";

interface EintragBearbeitenState {
  currentEintrag: EintragFrontend | null;
  setCurrentEintrag: (eintrag: EintragFrontend) => void;
  pageState: EintragFrontend | null;
  setPageState: (eintrag: EintragFrontend) => void;
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  showDeleteDialog: boolean;
  setShowDeleteDialog: (show: boolean) => void;
  selectedEintraege: EintragFrontend[];
  setSelectedEintraege: (eintraege: EintragFrontend[]) => void;
  deleteSelectedEintraege: () => Promise<void>;
}

export const useEintragBearbeitenState = create<EintragBearbeitenState>(
  (set, get) => ({
    currentEintrag: null,
    setCurrentEintrag: (eintrag: EintragFrontend) =>
      set({ currentEintrag: eintrag }),
    pageState: null,
    setPageState: (eintrag: EintragFrontend) => set({ pageState: eintrag }),
    openDialog: false,
    setOpenDialog: (open: boolean) => set({ openDialog: open }),
    showDeleteDialog: false,
    setShowDeleteDialog: (show: boolean) => set({ showDeleteDialog: show }),
    selectedEintraege: [],
    setSelectedEintraege: (eintraege: EintragFrontend[]) =>
      set({ selectedEintraege: eintraege }),
    deleteSelectedEintraege: async () => {
      const selectedEintraege = get().selectedEintraege;
      const dbname = selectedEintraege[0].DBNAME;
      const contid = selectedEintraege[0].CONTID;
      const dataid = selectedEintraege[0].DATAID;

      const deleteEintrag = async (itemId: string) => {
        const response = await axios.delete(
          `/api/db/${dbname}/cont/${contid}/pl/${dataid}/items/${itemId}`
        );
        return response.data;
      };

      for (const eintrag of selectedEintraege) {
        await deleteEintrag(eintrag.ITEMID);
      }

      set({ selectedEintraege: [] });
      set({ showDeleteDialog: false });
    },
  })
);

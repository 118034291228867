import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import ABKControlledExpansionPanel from "../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import axios from "axios";
import { AISummary, Job } from "../../../../../core/interfaces/Job";
import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Data_Conttype_cpl } from "../../../../../abk-shared/interfaces/abk9SvgIcons";
import { Cont } from "../../../../../core/interfaces/Cont";
import { Versioned } from "../../../../../core/interfaces/Versioned";
import { Popup } from "@progress/kendo-react-popup";
import { xIcon, sparklesIcon } from "@progress/kendo-svg-icons";
import { FloatingActionButton } from "@progress/kendo-react-buttons";
import React from "react";
import { ABKPageLoader } from "../../../../../abk-shared/components/atoms/ABKPageLoader";
import ABKWindow from "../../../../../abk-shared/components/molecules/ABKWindow";
import ABKSaveBalken from "../../../../../abk-shared/components/molecules/ABKSaveBalken";
import { WindowActionsBar } from "@progress/kendo-react-dialogs";
import AIGenerateBeschreibung from "../../../components/AIGenerateBeschreibung";

interface BeschreibungPanelProps {
  title: React.ReactNode;
  isExpanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
  beschreibung: string;
  setBeschreibung: ((event: TextAreaChangeEvent) => void) | undefined;
  updatePageState: (state: any) => void;
  pageState: Versioned<Cont> | undefined;
}

const BeschreibungPanel = ({
  title,
  isExpanded,
  onAction,
  beschreibung,
  setBeschreibung,
  updatePageState,
  pageState,
}: BeschreibungPanelProps) => {
  return (
    <ABKControlledExpansionPanel
      title={title}
      expanded={isExpanded}
      key="beschreibung"
      onAction={onAction}
    >
      {isExpanded && (
        <div onKeyDown={(e) => e.stopPropagation()}>
          {pageState && (
            <AIGenerateBeschreibung
              pageState={pageState}
              updatePageState={updatePageState}
            />
          )}

          <TextArea
            defaultValue={"Textarea"}
            autoSize={true}
            style={{
              resize: "none",
              overflowX: "hidden",
            }}
            value={beschreibung}
            onChange={setBeschreibung}
          />
        </div>
      )}
    </ABKControlledExpansionPanel>
  );
};

export default BeschreibungPanel;

import { Address } from "../../../abk-shared/components/atoms/ABKAddressSelect";
import { GridFieldWithInternalValue } from "../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import {
  ItemType,
  ItemTypeDisplayed,
} from "../pages/Parameterlisten/Einträge/maps";

export interface Herausgeber {
  firma: Firma;
  person: Kontaktperson;
}

export interface Kommunikation {
  adresse: Address;
  email: string;
  telefon: string;
  fax: string;
  www: string;
  zusatzinfo: string;
}

export interface Firma {
  name: string;
  kommunikation: Kommunikation;
}

export interface Kontaktperson {
  vorname: string;
  nachname: string;
  kommunikation: Kommunikation;
}

export interface ParameterListe {
  Meta: {
    Rowchanged: string;
    Right: string;
  };
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  SUBTYPE: string;
  Kenndaten: Kenndaten;
  NOTIZ: string;
  ANMERK: string;
  REFCONTID: string;
  REFDATAID: string;
  ONDTEXP: boolean;
}

export interface Kenndaten {
  KENNUNG: string;
  VERSIONSNR: string;
  VERSIONSDATE: string | null;
  STATUS: ParameterListeStatus;
  BEZ: string;
  BESCH: string;
  DLURL: string;
  Herausgeber: Herausgeber;
  LOGO: string;
  LOGOFORMAT: LogoFormat;
  FUNKTGSKENN: string;
  FUNKTGSDATAID: string;
  PRODGSKENN: string;
  PRODGSDATAID: string;
}

export enum LogoFormat {
  JPEG = "JPEG",
  PNG = "PNG",
  GIF = "GIF",
  EMPTY = "",
}

export enum ParameterListeStatus {
  Entwurf = "Entwurf",
  Freigegeben = "Freigegeben",
}

export enum EintragBerechnungsart {
  keine = "Keine (Eingabe Kennwert)",
  summeBilden = " Summe bilden",
}

export interface EintragBase {
  Meta: {
    InstanceApiUrl: string;
    Rowchanged: string;
    Right: string;
  };
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  ITEMID: string;
  BEZ: string;
  KOMMENTAR: string;
  Funktion: string[];
  Produkt: string[];
  VARNAME: string;
  PIKTID: string;
  IFCReferenzen:
    | {
        Entity: string;
        Set: string;
        Property: string;
      }[]
    | null;
  SHOWIF: string;
  EH: string;
  NUMNK: number;
  NUMMIN: number;
  NUMMAX: number;

  FORMEL: string;
  AWList_Num: number[];
  AWList_Text: AWList_Text[] | null;
  AWUSEREN: boolean;
}

export type EintragBackend = EintragBase & {
  ITEMTYPE: ItemType;
  FAVORIT: boolean;
  REQUIRED: boolean;
  SUMMEBILDEN: boolean;
  MENGENABH: boolean;
  NUMMIN: number;
  NUMMAX: number;
};

export type EintragFrontend = EintragBase & {
  ITEMTYPE: ItemTypeDisplayed;
  FAVORIT: EintragCheckboxValue;
  REQUIRED: EintragCheckboxValue;
  SUMMEBILDEN: EintragCheckboxValue;
  MENGENABH: EintragCheckboxValue;
  NUMMIN: GridFieldWithInternalValue;
  NUMMAX: GridFieldWithInternalValue;
};

export enum EintragCheckboxValue {
  selected = "Selektiert",
  notSelected = "Nicht selektiert",
}

export interface AWList_Text {
  Eintrag: string;
  Bezeichnung: string;
}

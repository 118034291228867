import { Kenndaten } from "./Kenndaten";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";
import { useOutlet } from "react-router-dom";

/*
  "isPreviousVersion" ist für die Vorversion, auskommentiert, um einen Spur für die Vorversion
  der Gliederungssysteme zu behalten.
  Wenn die Vorversion für die Gliederungssystem implementiert ist,
  dann kann der folgende Code gelöscht werden, mit diesem Kommentar.
*/
// type Props = { isPreviousVersion?: boolean };
export default function KenndatenWrapper() {
  const outlet = useOutlet();
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  if (!isValid) return <></>;

  return (
    // `outlet` auch für Vorversion
    outlet || (
      <Kenndaten
        /*
          "isPreviousVersion" ist für die Vorversion, auskommentiert, um einen Spur für die Vorversion
          der Gliederungssysteme zu behalten.
          Wenn die Vorversion für die Gliederungssystem implementiert ist,
          dann kann der folgende Code gelöscht werden, mit diesem Kommentar.
        */
        // isPreviousVersion={isPreviousVersion}
        key={`kenndaten-${db}-${contId}`}
        dbName={db as string}
        contId={contId as string}
      />
    )
  );
}

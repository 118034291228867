import { useParams } from "react-router-dom";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";
import ParameterlisteLayout from "./ParameterlisteLayout";

export default function ParameterlisteLayoutWrapper() {
  const { plID } = useParams();

  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  if (!isValid || !plID) return <></>;

  return (
    <ParameterlisteLayout
      key={`parameterliste-layout-${db}-${contId}-${plID}`}
      dbName={db!}
      contId={contId!}
      plID={plID}
    />
  );
}

import ABKSaveBalken from "../../../../../../abk-shared/components/molecules/ABKSaveBalken";
import { useEintragBearbeitenState } from "./useEintragBearbeitenState";
import { ReactNode, useEffect } from "react";
import { parTypePanelsMap } from "./panels/ParTypePanelsMap";
import ABKWindow from "../../../../../../abk-shared/components/molecules/ABKWindow";
import "./windowStyles.scss";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import _ from "lodash";
import useIsMobileTemplate from "../../../../../../abk-shared/utility/useIsMobileTemplate";
import useSaveDataItem from "./useSaveDataItem";

type Props = {
  onClose: () => void;
};

const EintragBearbeitenDialog = ({ onClose }: Props) => {
  const { currentEintrag, pageState, setPageState } =
    useEintragBearbeitenState();
  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  useEffect(() => {
    if (currentEintrag) setPageState(currentEintrag);
  }, [currentEintrag]);

  const saveDataItemMutation = useSaveDataItem({
    itemId: currentEintrag?.ITEMID || "",
    dbname: currentEintrag?.DBNAME || "",
    contid: currentEintrag?.CONTID || "",
    dataid: currentEintrag?.DATAID || "",
    eintrag: pageState as EintragFrontend,
  });

  if (!pageState) return <></>;

  const panels: ReactNode[] = parTypePanelsMap.get(pageState.ITEMTYPE) || [];

  return (
    <ABKWindow
      initialHeight={browserWindowHeight}
      title={`Eintrag bearbeiten`}
      onClose={onClose}
    >
      <div className="wrapper-panels-and-balken">
        <div className="panels">
          {panels.map((panel, index) => (
            <div key={index}>{panel}</div>
          ))}
        </div>
        <div className="save-balken-container">
          <ABKSaveBalken
            edited={!_.isEqual(pageState, currentEintrag)}
            onSave={() => {
              saveDataItemMutation.mutate();
            }}
            onReset={() => setPageState(currentEintrag as EintragFrontend)}
            isInline={true}
          />
        </div>
      </div>
    </ABKWindow>
  );
};

export default EintragBearbeitenDialog;

import { useQueryClient } from "@tanstack/react-query";
import ABKConfirmationDialog from "../../../../../abk-shared/components/molecules/ABKConfirmationDialog";
import ABKBasisGrid from "../../../../../abk-shared/components/organisms/ABKBasisGrid";
import { EintragFrontend } from "../../../interfaces/parameterlisten";
import EintraegeCard from "./EintraegeCard";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";
import useEintraege from "./useEintraege";

type Props = { eintraege: EintragFrontend[]; isFetching: boolean };
const PLEintraegeGrid = ({ eintraege, isFetching }: Props) => {
  const {
    currentEintrag,
    setCurrentEintrag,
    showDeleteDialog,
    setShowDeleteDialog,
    selectedEintraege,
    setSelectedEintraege,
    deleteSelectedEintraege,
  } = useEintragBearbeitenState();

  const { columnsDefinition, customCells, gridActions } = useEintraege();

  const queryClient = useQueryClient();

  return (
    <>
      <ABKBasisGrid
        groupable={false}
        sortable={false}
        data={eintraege}
        initialSelectedItems={currentEintrag ? [currentEintrag] : []}
        onSelectionChange={(event) => {
          const selectedItem = event[0] as EintragFrontend;
          setSelectedEintraege([...event] as EintragFrontend[]);
          setCurrentEintrag(selectedItem);
        }}
        isLoading={isFetching}
        columnsDefinition={columnsDefinition}
        customCells={customCells}
        gridId="pl"
        dataItemKey="ITEMID"
        gridActions={gridActions}
        mobileGridRow={(
          rowRef,
          item,
          filterValue,
          isSelected,
          setDataItemSelected
        ) => (
          <EintraegeCard
            rowRef={rowRef}
            item={item as EintragFrontend}
            filterValue={filterValue}
            isSelected={isSelected}
            setDataItemSelected={setDataItemSelected}
          />
        )}
      />
      {showDeleteDialog && (
        <ABKConfirmationDialog
          message={
            selectedEintraege.length === 1
              ? `Wollen Sie den Eintrag wirklich löschen?`
              : `Wollen Sie ${selectedEintraege.length} Einträge wirklich löschen?`
          }
          onConfirm={async () => {
            const contid = selectedEintraege[0].CONTID;
            await deleteSelectedEintraege();
            await queryClient.invalidateQueries({
              queryKey: ["eintraege", contid],
            });
            setShowDeleteDialog(false);
          }}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
    </>
  );
};

export default PLEintraegeGrid;

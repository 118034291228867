import { useEffect, useState } from "react";
import { IField } from "./IField";
import { useEintragBearbeitenState } from "../useEintragBearbeitenState";
import EintragBearbeitenExpansionPanelVorschau from "./EintragBearbeitenExpansionPanelVorschau";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKFormCheckbox from "../../../../../../../abk-shared/components/atoms/ABKFormCheckbox";
import ABKFormFieldInput from "../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import ABKNumericInput from "../../../../../../../abk-shared/components/atoms/ABKNumericInput";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import { booleanFieldMapper, booleanFieldReverseMapper } from "../../maps";
import { createInternalValueForNumber } from "../../convertBackendEintrag";

const ZahlPanel = () => {
  const { pageState, setPageState } = useEintragBearbeitenState();
  const [expanded, setExpanded] = useState(false);

  const [fields, setFields] = useState<IField[]>([]);

  const fieldEinheit: IField = { label: "Einheit", value: pageState?.EH || "" };
  const fieldNachkommastellen: IField = {
    label: "Nachkommastellen",
    value: pageState?.NUMNK || "",
  };
  const fieldMinimalwert: IField = {
    label: "Minimalwert",
    value: pageState?.NUMMIN.displayed,
  };
  const fieldMaximalwert: IField = {
    label: "Maximalwert",
    value: pageState?.NUMMAX.displayed,
  };
  const fieldSummeBilden: IField = {
    label: "Summe bilden",
    value: pageState?.SUMMEBILDEN || "",
  };
  const fieldMengenabhängigerKennwert: IField = {
    label: "Mengenabhängiger Kennwert",
    value: pageState?.MENGENABH || "",
  };

  useEffect(() => {
    setFields([
      fieldEinheit,
      fieldNachkommastellen,
      fieldMinimalwert,
      fieldMaximalwert,
      fieldSummeBilden,
      fieldMengenabhängigerKennwert,
    ]);
  }, [pageState]);

  return (
    <ABKControlledExpansionPanel
      testProps={{ ariaLabel: "expansion-panel-zahl" }}
      title={
        <EintragBearbeitenExpansionPanelVorschau
          title="Zahl"
          fields={fields}
          data-testid="test-expansion-panel-title-zahl"
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormFieldInput
                  data-testid="test-label-zahl-einheit"
                  editorId={"EH"}
                  label={fieldEinheit.label}
                  value={fieldEinheit.value as string}
                  setValue={(value: string) => {
                    setPageState({
                      ...pageState,
                      EH: value,
                    } as EintragFrontend);
                  }}
                />
                <ABKNumericInput
                  editorId={"NUMNK"}
                  label={fieldNachkommastellen.label}
                  value={pageState?.NUMNK}
                  setValue={(value: number | null) => {
                    setPageState({
                      ...pageState,
                      NUMNK: value,
                    } as EintragFrontend);
                  }}
                  readOnly={false}
                  onlyDecimal={true}
                  minValue={0}
                  maxValue={5}
                />
              </ABKFormRow>,

              <ABKFormRow>
                <ABKNumericInput
                  editorId={"NUMMIN"}
                  label={fieldMinimalwert.label}
                  value={pageState?.NUMMIN?.internal}
                  setValue={(value: number | null) => {
                    setPageState({
                      ...pageState,
                      NUMMIN: createInternalValueForNumber(value),
                    } as EintragFrontend);
                  }}
                  customFormat={`n${pageState?.NUMNK || 0}`}
                  readOnly={false}
                />
                <ABKNumericInput
                  editorId={"NUMMax"}
                  label={fieldMaximalwert.label}
                  value={pageState?.NUMMAX?.internal}
                  setValue={(value: number | null) => {
                    setPageState({
                      ...pageState,
                      NUMMAX: createInternalValueForNumber(value),
                    } as EintragFrontend);
                  }}
                  customFormat={`n${pageState?.NUMNK || 0}`}
                  readOnly={false}
                />
              </ABKFormRow>,

              <ABKFormRow>
                <ABKFormCheckbox
                  editorId={"SUMMEBILDEN"}
                  label={fieldSummeBilden.label}
                  value={booleanFieldReverseMapper.get(pageState?.SUMMEBILDEN)}
                  setValue={(value: boolean) => {
                    setPageState({
                      ...pageState,
                      SUMMEBILDEN: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                  tooltip={
                    "Bei der Auswahl von 'Summe bilden' wird der Kennwert für jede Leistungsgruppe bzw. jedes Leistungsverzeichnis summiert und als Gesamtwert angezeigt."
                  }
                />
                <ABKFormCheckbox
                  editorId={"MENGENABH"}
                  label={fieldMengenabhängigerKennwert.label}
                  value={booleanFieldReverseMapper.get(pageState?.MENGENABH)}
                  setValue={(value: boolean) => {
                    setPageState({
                      ...pageState,
                      MENGENABH: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};

export default ZahlPanel;

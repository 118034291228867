import { NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import { ABK_READ_ONLY } from "../../../theme/customClassNames";
import ABKLabel from "../ABKLabel";

interface AbkFormFieldInputProps {
  editorId: string;
  label: string;
  value: number | null | undefined;
  setValue: (value: number | null) => void;
  readOnly: boolean;
  minValue?: number;
  maxValue?: number;
  onlyDecimal?: boolean;
  customFormat?: string;
}

const ABKNumericInput: React.FC<AbkFormFieldInputProps> = (props) => {
  const handleValueChange = (event: any) => {
    const newValue = event.value;

    // If onlyDecimal is true, restrict to integer values only
    if (props.onlyDecimal && newValue % 1 !== 0) {
      return;
    }

    props.setValue(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (props.onlyDecimal && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={props.editorId} className="k-d-flex">
        {props.label}
      </ABKLabel>
      <NumericTextBox
        className={props.readOnly ? ABK_READ_ONLY : ""}
        id={props.editorId}
        name={props.editorId}
        value={props.value}
        onChange={handleValueChange}
        readOnly={props.readOnly}
        spinners={!props.readOnly}
        format={
          !props.customFormat
            ? {
                useGrouping: true,
                style: "decimal",
              }
            : props.customFormat
        }
        min={props.minValue}
        max={props.maxValue}
        onKeyDown={handleKeyDown}
      />
    </ABKFormFieldInputWrapper>
  );
};

export default ABKNumericInput;

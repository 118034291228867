import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ParameterListe } from "../interfaces/parameterlisten";
import { Versioned } from "../../../core/interfaces/Versioned";
import formatParameterlisteWithEtag from "./formatParameterlisteWithEtag";

export const QUERY_KEY_PARAMETERLISTE = "pl";

const useGetSingleParameterliste = (
  db: string,
  contId: string,
  plID: string
) => {
  const getSingleParameterliste = async () => {
    const url = `/api/db/${db}/cont/${contId}/pl/${plID}`;
    const response = await axios.get<ParameterListe>(url);
    return formatParameterlisteWithEtag(response);
  };

  return useQuery<Versioned<ParameterListe>>({
    queryKey: [QUERY_KEY_PARAMETERLISTE, db, contId, plID],
    queryFn: () => getSingleParameterliste(),
    enabled: !!plID,
  });
};

export default useGetSingleParameterliste;

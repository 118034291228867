import imageCompression from "browser-image-compression";
import { LogoFormat } from "../../../../../../interfaces/parameterlisten";

// From: https://stackoverflow.com/a/57272491
export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function getLogoFormat(base64Format: string) {
  if (base64Format === "image/jpeg") return LogoFormat.JPEG;

  if (base64Format === "image/gif") return LogoFormat.GIF;

  if (base64Format === "image/png") return LogoFormat.PNG;

  return LogoFormat.EMPTY;
}

export function generateBase64ForImage(
  imageString: string,
  imageFormat: LogoFormat
) {
  const hasImage = typeof imageString === "string" && imageString.length !== 0;

  const imagePrefix = generateBase64Prefix(imageFormat);
  const imageBase64 = `${imagePrefix}${imageString}`;

  return { hasImage, imageBase64 };
}

function generateBase64Prefix(format: LogoFormat) {
  let imageFormat = "image/png";

  switch (format) {
    case LogoFormat.JPEG:
      imageFormat = "image/jpg";
      break;
    case LogoFormat.PNG:
    case LogoFormat.EMPTY:
      imageFormat = "image/png";
      break;
    case LogoFormat.GIF:
      imageFormat = "image/gif";
      break;
  }

  return `data:${imageFormat};base64,`;
}

export async function compressFileToLessThan64kB(inputFile: any) {
  const result = { compressedFile: null as File | null, isCompressed: false };
  if (!inputFile.size) return result;

  const inputFileSizeInKb = inputFile.size / 1024;
  if (inputFileSizeInKb < 64) return result;

  const options = {
    maxSizeMB: 0.063,
    useWebWorker: true,
    /*
      Mache die `maxIteration` größer, da die Default-Anzahl von Iterations
      manchmal nicht genug ist.
      From: https://github.com/Donaldcwl/browser-image-compression/issues/140
    */
    maxIteration: 30,
  };
  result.compressedFile = await imageCompression(inputFile, options);
  result.isCompressed = true;
  return result;
}

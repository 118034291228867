import { Checkbox } from "@progress/kendo-react-inputs";
import { FunctionSetDataItemSelected } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/useGridRowSelection";
import { TestProps } from "../../../../../abk-shared/interfaces/TestProps";

type Props = {
  data: any;
  isSelected: boolean | undefined;
  setDataItemSelected: FunctionSetDataItemSelected;
  testProps?: TestProps | undefined;
};
const CardCheckbox = ({
  data,
  isSelected,
  setDataItemSelected,
  testProps,
}: Props) => (
  <span style={{ maxWidth: "fit-content" }}>
    <Checkbox
      aria-label={testProps?.ariaLabel}
      size="large"
      style={{ width: 30, height: 30 }}
      value={isSelected}
      onChange={() => {
        setDataItemSelected(data, !data.selected);
      }}
      // Verhindere, dass die "onClick" von der Card ausgelöst wird
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  </span>
);

export default CardCheckbox;

import {
  DropDownList,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";

import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKLabel from "../ABKLabel";
import ABKInfoPopup from "../ABKInfoPopup";

interface Props extends DropDownListProps {
  label: string;
  editorId: string;
  tooltip?: string;
}

export default function ABKFormDropdown({
  label,
  editorId,
  tooltip,
  ...dropDownListProps
}: Props) {
  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={editorId}>
        {label}
        {tooltip && <ABKInfoPopup popupContent={tooltip} />}
      </ABKLabel>
      <DropDownList name={editorId} title={label} {...dropDownListProps} />
    </ABKFormFieldInputWrapper>
  );
}

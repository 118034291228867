import { CardActions } from "@progress/kendo-react-layout";
import { AbkIconsMap } from "../../../../../abk-shared/interfaces/abk9SvgIcons";
import { SvgIcon } from "@progress/kendo-react-common";
import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import React from "react";
import useIsMobileTemplate from "../../../../../abk-shared/utility/useIsMobileTemplate";
import { FunctionSetDataItemSelected } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/useGridRowSelection";
import CardCheckbox from "./DateienCardCheckbox";

type Props = {
  data: ContWithLastChangedModified & { selected?: boolean };
  onCardClick: (containerId: string) => unknown;
  isTemplate: boolean | undefined;
  buttonText: string;
  buttonIcon: string;
  isSelected?: boolean;
  setDataItemSelected?: FunctionSetDataItemSelected;
};

export default function DateienCardActions({
  data,
  onCardClick,
  isTemplate,
  buttonText,
  buttonIcon,
  isSelected,
  setDataItemSelected,
}: Props) {
  const isMobileTemplate = useIsMobileTemplate();

  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const onButtonClick = async () => {
    const returnValue = onCardClick(data.CONTID);
    if (returnValue instanceof Promise) {
      setIsButtonLoading(true);
      await returnValue;
      setIsButtonLoading(false);
    }
  };

  const hasSelection = !!setDataItemSelected;

  return (
    <CardActions
      layout="stretched"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      {isTemplate && (
        <div className="favorite-button-container" title="Vorlage">
          <SvgIcon
            icon={AbkIconsMap.get("Symbol.Star")}
            height={24}
            width={24}
          />
        </div>
      )}
      <div className="favorite-button">
        <span title="Favorit">
          <SvgIcon
            icon={AbkIconsMap.get("General.Favorite")}
            height={24}
            width={24}
          />
        </span>
      </div>
      {hasSelection ? (
        <CardCheckbox
          data={data}
          isSelected={isSelected}
          setDataItemSelected={setDataItemSelected}
        />
      ) : (
        <Button
          style={{ maxWidth: "fit-content", textDecoration: "underline" }}
          startIcon={
            isButtonLoading ? (
              <Loader size="small" />
            ) : (
              <SvgIcon icon={AbkIconsMap.get(buttonIcon)} />
            )
          }
          disabled={isButtonLoading}
          onClick={onButtonClick}
          title={buttonText}
        >
          {isMobileTemplate ? "" : buttonText}
        </Button>
      )}
    </CardActions>
  );
}

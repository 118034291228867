import { Button } from "@progress/kendo-react-buttons";
import { db } from "../../core/db/db";
import { dbJobs } from "../../core/db/dbJobs";

const LocalBrowserConfiguration = () => (
  <div>
    <div className="k-d-flex k-gap-xs k-flex-wrap">
      <Button
        onClick={() => {
          db.delete();
        }}
      >
        IndexedDB - Drafts Datenbank löschen
      </Button>{" "}
      <Button
        onClick={() => {
          dbJobs.delete();
        }}
      >
        IndexedDB - Jobs Datenbank löschen
      </Button>{" "}
      <Button
        onClick={() => {
          localStorage.clear();
        }}
      >
        LocalStorage löschen
      </Button>
    </div>
  </div>
);

export default LocalBrowserConfiguration;

import React from "react";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";
import { PropsCustomCell } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import ABKBasisGridCellLink from "../../../../../abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridCellLink";
import { ParameterListe } from "../../../interfaces/parameterlisten";
import { useNavigate } from "react-router-dom";
import { CreateGridActionsFunction } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridAction";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { styleVersionNumber } from "../../../../../abk-shared/utility/styleVersionNumber";
import createParameterlisteURL from "./createParameterlisteURL";

const useParameterlisten = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const columnsDefinition = React.useMemo(() => {
    const columns = [
      {
        field: "Kenndaten.BEZ",
        title: "Bezeichnung",
        orderIndex: 1,
        minWidth: 100,
      },
      {
        field: "Kenndaten.KENNUNG",
        title: "Kennung",
        orderIndex: 2,
        minWidth: 100,
      },
      {
        field: "Kenndaten.VERSIONSNR",
        title: "Version",
        orderIndex: 3,
        minWidth: 100,
      },
      {
        field: "Kenndaten.VERSIONSDATE",
        title: "Versionsdatum",
        orderIndex: 4,
        minWidth: 100,
      },
      {
        field: "Kenndaten.STATUS",
        title: "Status",
        orderIndex: 5,
        minWidth: 100,
      },
      {
        title: "Format",
        field: "SUBTYPE",
        orderIndex: 6,
        minWidth: 100,
      },
      {
        title: "GS Funktion",
        field: "Kenndaten.FUNKTGSKENN",
        orderIndex: 7,
        minWidth: 100,
      },
      {
        title: "GS Produktgruppe",
        field: "Kenndaten.PRODGSKENN",
        orderIndex: 8,
        minWidth: 100,
      },
      {
        field: "DATAID",
        title: "Data-ID",
        show: false,
        orderIndex: 9,
        minWidth: 100,
      },
    ] as GridColumnPropsCustom[];

    const version = 2;

    const migrate: GridColumnsMigrateFunction = (persistedColumns, version) => {
      if (version === 1) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const datei = newPersistedColumns.columns[0];
        const lastChanged = datei.children![3];
        // Wurde in Version 2 hinzugefügt, um die Daten zu sortieren
        lastChanged.hasInternalValue = true;

        return newPersistedColumns;
      }

      if (version === 2) {
        const newPersistedColumns = persistedColumns as PersistedColumns;
        const datei = newPersistedColumns.columns[0];

        datei.children = [
          {
            field: "Kenndaten.BEZ",
            title: "Bezeichnung",
            orderIndex: 1,
            minWidth: 100,
          },
          {
            field: "Kenndaten.KENNUNG",
            title: "Kennung",
            orderIndex: 2,
            minWidth: 100,
          },
          {
            field: "Kenndaten.VERSIONSNR",
            title: "Version",
            orderIndex: 3,
            minWidth: 100,
          },
          {
            field: "Kenndaten.VERSIONSDATE",
            title: "Versionsdatum",
            orderIndex: 4,
            minWidth: 100,
          },
          {
            field: "Kenndaten.STATUS",
            title: "Status",
            orderIndex: 5,
            minWidth: 100,
          },
          {
            title: "Format",
            field: "SUBTYPE",
            orderIndex: 6,
            minWidth: 100,
          },
          {
            title: "GS Funktion",
            field: "Kenndaten.FUNKTGSKENN",
            orderIndex: 7,
            minWidth: 100,
          },
          {
            title: "GS Produktgruppe",
            field: "Kenndaten.PRODGSKENN",
            orderIndex: 8,
            minWidth: 100,
          },
          {
            field: "DATAID",
            title: "Data-ID",
            show: false,
            orderIndex: 9,
            minWidth: 100,
          },
        ] as GridColumnPropsCustom[];

        return newPersistedColumns;
      }

      return persistedColumns as PersistedColumns;
    };

    return { columns, version, migrate };
  }, []);

  const customCells = React.useMemo(
    () => [
      {
        columnField: "Kenndaten.BEZ",
        render: (props: PropsCustomCell) => (
          <ABKBasisGridCellLink
            {...props}
            text={props.dataItem.Kenndaten.BEZ}
            to={createParameterlisteURL(
              props.dataItem.DATAID,
              props.dataItem.DBNAME,
              props.dataItem.CONTID
            )}
            testProps={{ dataTestid: "link-to-parameterliste" }}
          />
        ),
      },
      {
        columnField: "Kenndaten.VERSIONSNR",
        render: (props: PropsCustomCell) => (
          <td {...props.tdProps}>
            {styleVersionNumber(props.dataItem.Kenndaten.VERSIONSNR)}
          </td>
        ),
      },
    ],
    []
  );

  const parameterlistenActionHandlers = React.useMemo(
    () => ({
      open: (_event: any, selectedParameterlisten: ParameterListe[]) => {
        const parameterlisteSelected = selectedParameterlisten[0];
        const parameterlisteId = parameterlisteSelected.DATAID;
        const parameterlisteUrl = createParameterlisteURL(
          parameterlisteId,
          parameterlisteSelected.DBNAME,
          parameterlisteSelected.CONTID
        );
        navigate(parameterlisteUrl);
      },

      delete: async (
        _event: any,
        selectedParameterlisten: ParameterListe[]
      ) => {
        const dbname = selectedParameterlisten[0].DBNAME;
        const contid = selectedParameterlisten[0].CONTID;

        const deletePL = async (dataid: string) => {
          const response = await axios.delete(
            `/api/db/${dbname}/cont/${contid}/pl/${dataid}`
          );
          return response.data;
        };

        for (const pl of selectedParameterlisten) {
          await deletePL(pl.DATAID);
          queryClient.invalidateQueries({ queryKey: ["pl", dbname, contid] });
        }
      },
    }),
    [navigate, queryClient]
  );

  const gridActions: CreateGridActionsFunction = React.useCallback(
    (selectedItems: any[]) => {
      const numberOfSelectedItems = selectedItems.length;

      const defaultActions = [
        {
          icon: "Navigation.Open",
          text: "Öffnen",
          onClick: (event: any) =>
            parameterlistenActionHandlers.open(event, selectedItems),
        },
        {
          icon: "Grid.InsertEnd",
          text: "Hinzufügen am Tabellenende",
          onClick: () => alert("Hinzufügen am Tabellenende"),
        },

        {
          icon: "Edit.Delete",
          text: "Löschen",
          onClick: (event: any) =>
            parameterlistenActionHandlers.delete(event, selectedItems),
        },
        {
          icon: "Grid.Duplicate",
          text: "Duplizieren",
          onClick: () => alert("Duplizieren"),
        },
        {
          icon: "General.Import",
          text: "Importieren",
          onClick: () => alert("Importieren"),
        },
      ];

      if (numberOfSelectedItems === 1) {
        return [...defaultActions];
      }
      if (numberOfSelectedItems > 1) {
        const filterTexts = ["Öffnen", "Duplizieren"];
        const filteredActions = defaultActions.filter(
          (action) => !filterTexts.includes(action.text)
        );
        return [
          <>{`${numberOfSelectedItems} ausgewählt`}</>,
          ...filteredActions,
        ];
      }

      return defaultActions.filter(
        (action) =>
          action.text.includes("Importieren") ||
          action.text.includes("Hinzufügen am Tabellenende")
      );
    },
    [parameterlistenActionHandlers]
  );

  return { columnsDefinition, customCells, gridActions };
};

export default useParameterlisten;

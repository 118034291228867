const ListHeader = ({ headerText }: { headerText: string }) => {
  return (
    <div
      style={{
        color: "rgb(160, 160, 160)",
        fontSize: 14,
        padding: "10px 0",
      }}
    >
      {headerText}
    </div>
  );
};

export default ListHeader;

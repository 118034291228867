import { Card, CardBody } from "@progress/kendo-react-layout";
import React from "react";
import Highlighter from "react-highlight-words";
import ABKFakeLink from "../../../../../abk-shared/components/atoms/ABKFakeLink";
import { DATA_KEY } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import { FunctionSetDataItemSelected } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/useGridRowSelection";
import { useQuickInfoState } from "../../../../../abk-shared/stores/useQuickInfoState";
import useIsMobileTemplate from "../../../../../abk-shared/utility/useIsMobileTemplate";
import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";
import useIsCardActive from "../../../hooks/useIsCardActive";
import "../../organisms/Dateien.css"; // Stil-Datei für das Listenelement
import DateienCardActions from "./DateienCardActions";
import DateienCardBelowBody from "./DateienCardBelowBody";
import DateienCardHeader from "./DateienCardHeader";
import "./style.scss";

interface CardElementProps {
  data: ContWithLastChangedModified & { selected?: boolean }; // Verwenden Sie den definierten Datentyp
  onCardClick: (containerId: string) => unknown;
  isTemplate?: boolean;
  buttonText: string;
  buttonIcon: string;
  filterValue?: string;
  shouldDisplayOwner?: boolean;
  isSelected?: boolean;
  setDataItemSelected?: FunctionSetDataItemSelected;
  rowRef?: React.RefObject<HTMLDivElement>;
  hasPaddingBottom?: boolean;
  dataTestid?: string;
}

const CardElement: React.FC<CardElementProps> = ({
  data,
  onCardClick,
  buttonText,
  buttonIcon,
  isTemplate,
  filterValue = "",
  shouldDisplayOwner = false,
  isSelected,
  setDataItemSelected,
  rowRef,
  hasPaddingBottom = false,
  dataTestid,
}) => {
  const { setQuickInfo } = useQuickInfoState();
  const isMobileTemplate = useIsMobileTemplate();
  const { isCardActive, setActiveCard } = useIsCardActive(data.CONTID);

  const showQuickInfoContent = (
    onCardClick: (containerId: string) => unknown
  ) => {
    setActiveCard(data.CONTID);
    setQuickInfo([
      {
        title: "Dateiname",
        content: (
          <ABKFakeLink
            onClick={() => {
              onCardClick(data.CONTID);
            }}
          >
            {data.BEZ}
          </ABKFakeLink>
        ),
      },
      {
        title: "Besitzer",
        content: data.OWNER || "",
      },
      {
        title: "Änderungsdatum",
        content: data.LASTCHANGED[DATA_KEY.DISPLAYED] || "",
      },
      {
        title: "Anmerkung",
        content: data.INTANMERK || "Keine Anmerkung vorhanden",
      },
    ]);
  };

  return (
    <div
      data-testid={dataTestid}
      ref={rowRef}
      style={{ paddingBottom: hasPaddingBottom ? "10px" : "0" }}
    >
      <Card
        className={"card-element " + (isCardActive ? "activeCard" : null)}
        title={data.BEZ}
        onClick={() => {
          showQuickInfoContent(onCardClick);
          if (setDataItemSelected) setDataItemSelected(data, true, true);
        }}
      >
        <DateienCardHeader
          data={data}
          filterValue={filterValue}
          onCardClick={() => onCardClick(data.CONTID)}
        />
        {!isMobileTemplate && (
          <CardBody className="content">
            <div className="beschreibung">
              <Highlighter
                searchWords={[filterValue]}
                textToHighlight={data.BESCH || ""}
              />
            </div>
          </CardBody>
        )}
        <DateienCardBelowBody
          data={data}
          filterValue={filterValue}
          shouldDisplayOwner={shouldDisplayOwner}
        />
        <DateienCardActions
          data={data}
          onCardClick={onCardClick}
          isTemplate={isTemplate}
          buttonText={buttonText}
          buttonIcon={buttonIcon}
          isSelected={isSelected}
          setDataItemSelected={setDataItemSelected}
        />
      </Card>
    </div>
  );
};

export default CardElement;

import { useOutlet } from "react-router-dom";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";
import ParameterListen from "./ParameterListen";

const WeitereParameterlisten = () => {
  const outlet = useOutlet();
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();

  if (!isValid) return <></>;

  return outlet || <ParameterListen dbName={db!} contId={contId!} />;
};

export default WeitereParameterlisten;

import { ParameterListe } from "../../../../../interfaces/parameterlisten";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import AbkOenormEditor from "../../../../../../../core/components/organisms/AbkOenormEditor";
import editorTools from "../../../../../../../core/components/organisms/AbkOenormEditor/editorTools";
import CustomViewHtmlHook from "../../../../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog/CustomViewHtmlHook";

interface BeschreibungFormProps {
  pageState: ParameterListe;
  setPageState: (parameterliste: ParameterListe) => void;
  isOpen: boolean;
  updateSections: () => void;
  readOnly: boolean;
}

const BeschreibungForm = ({
  pageState,
  setPageState,
  isOpen,
  updateSections,
}: BeschreibungFormProps) => {
  return (
    <ABKControlledExpansionPanel
      title="Beschreibung"
      key="beschreibungform"
      expanded={isOpen}
      onAction={updateSections}
    >
      <AbkOenormEditor
        tools={[
          ...editorTools.oenormTools,
          [CustomViewHtmlHook],
          ...editorTools.oenormTabelleTools,
          ...editorTools.tableTools,
          ...editorTools.extraTools,
        ]}
        value={pageState.Kenndaten.BESCH}
        setValue={(value) => {
          setPageState({
            ...pageState,
            Kenndaten: {
              ...pageState.Kenndaten,
              BESCH: value,
            },
          });
        }}
      ></AbkOenormEditor>
    </ABKControlledExpansionPanel>
  );
};

export default BeschreibungForm;

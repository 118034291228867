import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
  ExpansionPanelProps,
} from "@progress/kendo-react-layout";
import React from "react";
import { TestProps } from "../../../interfaces/TestProps";

export interface ABKControlledExpansionPanelProps extends ExpansionPanelProps {
  children: React.ReactNode;
  expanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
  testProps?: TestProps | undefined;
}

const ABKControlledExpansionPanel = (
  props: ABKControlledExpansionPanelProps
) => {
  return (
    <div aria-label={props.testProps?.ariaLabel}>
      <ExpansionPanel {...props}>
        {props.expanded && (
          <ExpansionPanelContent>{props.children}</ExpansionPanelContent>
        )}
      </ExpansionPanel>
    </div>
  );
};

export default ABKControlledExpansionPanel;
